import gml from './gml'
import kml from './kml'
import shape from './shape'
import geoTiff from './geo-tiff'

export default {
  gml,
  kml,
  shape,
  geoTiff
}

<template lang="html">
  <div class="zonemap-list" :class="groupByFields ? 'group-by-fields' : ''" style="position: relative">
    <MapItem
      v-for="map in orderedMaps"
      :key="map.id"
      :map="map"
      type="fieldBorder"
    />
    <FrsLoadingIndicator :loading="filterIsLoading" small />
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {orderBy} from 'lodash'
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'
import MapItem from './widgets/MapItem'

export default {
  components: {
    MapItem,
    FrsLoadingIndicator
  },
  props: {
    maps: Array
  },
  computed: {
    ...mapState('fieldRecordSystem/mapManagement', {
      groupByFields: state => state.ui.groupByFields,
      filterIsLoading: state => state.ui.filterIsLoading
    }),
    orderedMaps () {
      return orderBy(this.maps, ['generatedAt'], ['desc'])
    }
  }
}
</script>

<style lang="scss" scoped>
.zonemap-list {
  display: flex;
  flex-direction: column;
}
.group-by-fields {
  margin-left: 16px;}
</style>

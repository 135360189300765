<template>
  <div>
    <IxButton
      :disabled="disabled || loading"
      large
      icon="print"
      @click="print">
      <IxRes>frs.mapManagement.details.print</IxRes>
      <template v-if="loading" #icon>
        <FontAwesomeIcon :icon="icons.spinner" spin />
      </template>
    </IxButton>
  </div>
</template>

<script>
import {smarterPost} from '@helpers/vuex/data-loading'

import {notifications} from 'src/js/infrastructure'

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {faSpinner} from '@fortawesome/free-solid-svg-icons'

import IxButton from '@components/IxButton'

export default {
  components: {
    IxButton,
    FontAwesomeIcon
  },
  props: {
    mapIds: {
      type: Array,
      required: true
    },
    mapType: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean
    }
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    icons () {
      return {
        spinner: faSpinner
      }
    }
  },
  methods: {
    async print () {
      this.loading = true
      try {
        const blob = await smarterPost(`/api/v2/print/${this.mapType}`, this.mapIds, {
          axios: {
            responseType: 'blob'
          }
        })
        const objectUrl = URL.createObjectURL(blob)
        const windowProxy = window.open(objectUrl)

        // the document cannot be downloaded when revoking the objectUrl while the window is still open

        // adding the event listener without timer does not fire the event
        // see FAQ https://developer.mozilla.org/en-US/docs/Web/API/Window/open
        const timer = setInterval(() => {
          if (windowProxy.closed) {
            clearInterval(timer)
            URL.revokeObjectURL(objectUrl)
          }
        }, 500)

      } catch (error) {
        // response comes as blob: https://developer.mozilla.org/en-US/docs/Web/API/Blob#extracting_data_from_a_blob
        notifications.error(this.$i18n.translate('frs.mapManagement.notifications.printError'))
        const text = await error.response.data.text()
        console.error(text)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>

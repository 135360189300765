<template lang="html">
  <div class="map-management">
    <FrsRouterBackLink :target="previousPage">
      <template #text>
        <IxRes>headers.backToDashboard</IxRes>
      </template>
    </FrsRouterBackLink>
    <div class="map-management" style="position: relative;">
      <h3>
        <IxRes>headers.mapManagement</IxRes>
      </h3>
      <OrgLevelFilters v-if="!fieldId" />

      <div class="map-sections">
        <!-- Zone maps collapse -->
        <div style="position: relative;">
          <CollapsibleSection :value="openSection === 'zoneMap'" @input="openSection === 'zoneMap' ? openSection = null : openSection = 'zoneMap'">
            <template #header>
              <CollapsibleMapSectionHeader type="zoneMap" :expanded="openSection === 'zoneMap'">
                <IxRes>headers.zoneMaps</IxRes>
                <template #additional-info>
                  <IxButton refresh @click="refreshZoneMaps" />
                </template>
              </CollapsibleMapSectionHeader>
            </template>
            <FrsLoadingIndicator small :requests="['zoneMaps.forField', 'zoneMapInfos.forOrgUnit']" />

            <MapSectionWrapper v-if="openSection === 'zoneMap'" :maps-by-field-id="mapsByFieldIdByType.zoneMap">
              <template #controls>
                <ZoneMapSortingControls>
                  <template #checkboxLabel>
                    <IxRes>labels.showInactiveZoneMaps</IxRes>
                  </template>
                </ZoneMapSortingControls>
              </template>
              <template #list="{maps}">
                <ZoneMapList :maps="maps.filter(x => x.category === 'ManagementZoneMap')" />
              </template>
            </MapSectionWrapper>
          </CollapsibleSection>
        </div>

        <!-- Application maps collapse -->
        <CollapsibleSection :value="openSection === 'applicationMap'" @input="openSection === 'applicationMap' ? openSection = null : openSection = 'applicationMap'">
          <template #header>
            <CollapsibleMapSectionHeader type="applicationMap" :expanded="openSection === 'applicationMap'">
              <IxRes>headers.applicationMaps</IxRes>
            </CollapsibleMapSectionHeader>
          </template>

          <MapSectionWrapper v-if="openSection === 'applicationMap'" :maps-by-field-id="filteredApplicationMaps">
            <template #controls="{maps}">
              <ApplicationMapSortingControls :maps="maps" :crop-usage-lookup="cropUsageLookup" />
            </template>
            <template #list="{maps, fieldId}">
              <ApplicationMapList
                :maps="maps" :crop-usage-lookup="cropUsageLookup"
                :open-sub-section="!groupByFields || fieldId === openSubSectionFieldId ? openSubSection : null"
                @toggle="toggleSubSection({subSection: $event, fieldId})"
                @varioDoc="showVarioDocLoginModal = !showVarioDocLoginModal"
                @osb="showOsbModal = true"
                @nevonex="showNevonexModal = true"
                @exatrek="showExatrekModal = true"
              />
            </template>
          </MapSectionWrapper>
        </CollapsibleSection>

        <!-- Nutrient maps collapse -->
        <CollapsibleSection
          v-if="$can('Use.Frs.BaseFertilization')" :value="openSection === 'nutrientMap'"
          @input="openSection === 'nutrientMap' ? openSection = null : openSection = 'nutrientMap'"
        >
          <template #header>
            <CollapsibleMapSectionHeader type="nutrientMap" :expanded="openSection === 'nutrientMap'">
              <IxRes>headers.nutrientMaps</IxRes>
            </CollapsibleMapSectionHeader>
          </template>

          <MapSectionWrapper v-if="openSection === 'nutrientMap'" :maps-by-field-id="mapsByFieldIdByType.nutrientMap">
            <template #list="{maps}">
              <NutrientMapList :maps="maps" />
            </template>
          </MapSectionWrapper>
        </CollapsibleSection>

        <!-- Soil maps collapse -->
        <CollapsibleSection :value="openSection === 'soilMap'" @input="openSection === 'soilMap' ? openSection = null : openSection = 'soilMap'">
          <template #header>
            <CollapsibleMapSectionHeader type="soilMap" :expanded="openSection === 'soilMap'">
              <IxRes>headers.soilMaps</IxRes>
            </CollapsibleMapSectionHeader>
          </template>

          <MapSectionWrapper v-if="openSection === 'soilMap'" :maps-by-field-id="filteredSoilMaps">
            <template #controls="{maps}">
              <SoilMapSortingControls :maps="maps" />
            </template>
            <template #list="{maps, fieldId}">
              <SoilMapList
                :maps="maps"
                :open-sub-section="!groupByFields || fieldId === openSubSectionFieldId ? openSubSection : null"
                @toggle="toggleSubSection({subSection: $event, fieldId})"
              />
            </template>
          </MapSectionWrapper>
        </CollapsibleSection>

        <!-- Biomass maps collapse -->
        <CollapsibleSection :value="openSection === 'biomassMap'" @input="openSection === 'biomassMap' ? openSection = null : openSection = 'biomassMap'">
          <template #header>
            <CollapsibleMapSectionHeader type="biomassMap" :expanded="openSection === 'biomassMap'">
              <IxRes>headers.biomassMap</IxRes>
            </CollapsibleMapSectionHeader>
          </template>

          <MapSectionWrapper v-if="openSection === 'biomassMap'" :maps-by-field-id="mapsByFieldIdByType.zoneMap">
            <template #controls>
              <ZoneMapSortingControls>
                <template #checkboxLabel>
                  <IxRes>labels.showInactiveBiomassMaps</IxRes>
                </template>
              </ZoneMapSortingControls>
            </template>
            <template #list="{maps}">
              <BiomassMapList :maps="maps.filter(x => x.category === 'BiomassMap')" />
            </template>
          </MapSectionWrapper>
        </CollapsibleSection>

        <!-- Geometries collapse -->
        <CollapsibleSection :value="openSection === 'fieldBorder'" @input="openSection === 'fieldBorder' ? openSection = null : openSection = 'fieldBorder'">
          <template #header>
            <CollapsibleMapSectionHeader type="fieldBorder" :expanded="openSection === 'fieldBorder'">
              <IxRes>headers.geometries</IxRes>
            </CollapsibleMapSectionHeader>
          </template>

          <MapSectionWrapper v-if="openSection === 'fieldBorder'" :maps-by-field-id="filteredFieldLookup">
            <template #controls>
              <FieldSortingControls />
            </template>
            <template #list="{maps}">
              <FieldBorderList :maps="maps" />
            </template>
          </MapSectionWrapper>
        </CollapsibleSection>
      </div>

      <div class="button-container">
        <HelpBox v-if="selectionMode === 'download' && selectionType">
          <IxRes>messages.downloadHint</IxRes>
        </HelpBox>

        <BatchButtons
          @varioDoc="showVarioDocLoginModal = !showVarioDocLoginModal"
          @osb="showOsbModal = true"
          @nevonex="showNevonexModal = true"
        />

        <IxButton
          v-if="fieldId && $can('Create.Frs.ZoneMap')"
          icon="cloud-upload"
          large
          @click="showZoneMapImportModal = true"
        >
          <IxRes>zoneMapImport.buttons.startImport</IxRes>
        </IxButton>
        <IxButton
          v-if="fieldId"
          icon="cloud-upload"
          large
          @click="showSoilMapImportModal = true"
        >
          <IxRes>soilMapImport.buttons.startImport</IxRes>
        </IxButton>
        <IxButton
          v-if="fieldId"
          icon="cloud-upload"
          large
          @click="showSensorDataImportModal = true"
        >
          <IxRes>sensorDataImport.buttons.startImport</IxRes>
        </IxButton>
        <IxButton refresh large @click="refreshAllMaps">
          <IxRes>mapManagment.buttons.refreshMaps</IxRes>
        </IxButton>
      </div>

      <GeometryImportModal v-model="showZoneMapImportModal" @load="startZoneMapImport" />
      <GeometryImportModal
        v-model="showSoilMapImportModal"
        format-name="Shape, GeoTiff"
        accepted-extends=".zip, .shp, .dbf, .shx, .prj, .atx, .sbx, .qix, .aih, .cpg, .shp.xml, .tiff, .tif"
        :map-types="soilMapTypes"
        :map-type="mapType"
        @selectedMapType="setMapType"
        @load="startSoilMapImport"
      />

      <SensorDataImportModal
        v-model="showSensorDataImportModal"
        format-name="GeoTiff"
        accepted-extends=".tiff, .tif"
        :map-type="'sensorData'"
      />

      <ChangeMapNameModal
        v-model="showChangeMapNameModal"
      />

      <ChangeHeterogenityModal
        v-model="showChangeHeterogenityModal"
      />

      <FrsLoadingIndicator :requests="['mapManagement.cultivation.loadDetails', 'applicationMaps.forField', 'nutrientMaps.forField', 'nutrientMaps.forOrgUnit', 'applicationMaps.forOrgUnit']" />

      <RemovalScheduler :interval="100" />

      <OsbModal v-if="((selectedApplicationMapId && selectionType === 'applicationMap') || mapIdsForDownload.length) && showOsbModal" v-model="showOsbModal" :application-map-ids="selectedMapIds" />
      <LoginModal v-if="(selectedApplicationMapId || mapIdsForDownload.length) && selectionType === 'applicationMap' && showVarioDocLoginModal" v-model="showVarioDocLoginModal" @login="showMachineModal = !showMachineModal" />
      <MachineSelectionModal v-if="(selectedApplicationMapId || mapIdsForDownload.length) && selectionType === 'applicationMap' && showMachineModal" v-model="showMachineModal" />
      <NevonexModal v-if="((selectedApplicationMapId && selectionType === 'applicationMap') || mapIdsForDownload.length) && showNevonexModal" v-model="showNevonexModal" :application-map-ids="selectedMapIds" />
      <ExatrekModal v-if="(selectionType === 'applicationMap') && showExatrekModal" v-model="showExatrekModal" :application-map-ids="selectedMapIds" />
    </div>
  </div>
</template>

<script>
import {mapState, mapGetters, mapMutations, mapActions} from 'vuex'
import {mapFormFields, mapResources} from '@helpers/vuex'
import {toObject} from '@helpers/reducers'
import {smarterGet} from '@helpers/vuex/data-loading'

import {makeCropUsageFilter, makeDateFilter, makeModuleFilter, makeMapTypeFilter, makeCropUsageFilterForField} from 'src/js/store/modules/field-record-system/map-management/helpers'

import IxButton from '@components/IxButton'
import HelpBox from '@components/help/HelpBox'
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'
import FrsRouterBackLink from '@frs/components/base/FrsRouterBackLink'
import GeometryImportModal from '@components/forms/GeometryImportModal'
import SensorDataImportModal from './SensorDataImportModal'
import CollapsibleSection from '@components/CollapsibleSection'

import OsbModal from '@components/data-transfer/OsbModal'
import LoginModal from '@components/data-transfer/vario-doc/LoginModal'
import MachineSelectionModal from '@components/data-transfer/vario-doc/MachineSelectionModal'
import NevonexModal from '@components/data-transfer/NevonexModal'
import ExatrekModal from '@components/data-transfer/ExatrekModal'

import OrgLevelFilters from './overview/widgets/OrgLevelFilters'
import CollapsibleMapSectionHeader from './overview/widgets/CollapsibleMapSectionHeader'
import MapSectionWrapper from './overview/widgets/MapSectionWrapper'
import ZoneMapSortingControls from './overview/widgets/ZoneMapSortingControls'
import SoilMapSortingControls from './overview/widgets/SoilMapSortingControls'
import ZoneMapList from './overview/ZoneMapList'
import SoilMapList from './overview/SoilMapList'
import BiomassMapList from './overview/BiomassMapList'
import FieldBorderList from './overview/FieldBorderList'
import ApplicationMapSortingControls from './overview/widgets/ApplicationMapSortingControls'
import FieldSortingControls from './overview/widgets/FieldSortingControls'
import ApplicationMapList from './overview/ApplicationMapList'
import NutrientMapList from './overview/NutrientMapList'
import BatchButtons from './overview/widgets/BatchButtons'
import ChangeMapNameModal from './overview/widgets/ChangeMapNameModal'
import ChangeHeterogenityModal from './overview/widgets/ChangeHeterogenityModal'
import RemovalScheduler from './overview/widgets/RemovalScheduler'

import PermissionMixin from '@mixins/PermissionMixin'

export default {
  translationNamespace: 'frs.mapManagement',
  components: {
    BiomassMapList,
    FrsRouterBackLink,
    IxButton,
    HelpBox,
    BatchButtons,
    ChangeMapNameModal,
    ChangeHeterogenityModal,
    GeometryImportModal,
    SensorDataImportModal,
    FrsLoadingIndicator,
    OrgLevelFilters,
    CollapsibleMapSectionHeader,
    MapSectionWrapper,
    ZoneMapSortingControls,
    ZoneMapList,
    ApplicationMapSortingControls,
    ApplicationMapList,
    SoilMapList,
    NutrientMapList,
    CollapsibleSection,
    RemovalScheduler,
    OsbModal,
    LoginModal,
    NevonexModal,
    ExatrekModal,
    MachineSelectionModal,
    FieldBorderList,
    SoilMapSortingControls,
    FieldSortingControls
  },
  mixins: [
    PermissionMixin
  ],
  data () {
    return {
      showZoneMapImportModal: false,
      showSoilMapImportModal: false,
      showVarioDocLoginModal: false,
      showOsbModal: false,
      showMachineModal: false,
      showNevonexModal: false,
      showExatrekModal: false,
      cropUsageLookup: null,
      showSensorDataImportModal: false
    }
  },
  computed: {
    ...mapState('fieldRecordSystem/mapManagement/soilMapImport', [
      'soilMapTypes'
    ]),
    ...mapResources([
      'frs.zoneMaps.creation.messages'
    ]),
    ...mapFormFields('fieldRecordSystem/mapManagement', [
      'ui.openSection'
    ]),
    ...mapFormFields('fieldRecordSystem/mapManagement/soilMapImport', [
      'mapType'
    ]),
    ...mapState('fieldRecordSystem', {
      fieldData: state => state.data.field
    }),
    ...mapState('dataLoading', {
      requests: state => state.requests
    }),
    ...mapState('fieldRecordSystem/mapManagement', {
      groupByFields: state => state.ui.groupByFields,
      newMapName: state => state.ui.newMapName,
      heterogenity: state => state.ui.heterogenity,
      showFieldsWithoutMaps: state => state.ui.showFieldsWithoutMaps,
      mapSelection: state => state.ui.selection,
      selectionMode: state => state.ui.selectionMode,
      selectionType: state => state.ui.selectionType,
      cropUsageFilter: state => state.ui.cropUsageFilter,
      cropUsageByFieldFilter: state => state.ui.cropUsageByFieldFilter,
      moduleFilter: state => state.ui.moduleFilter,
      dateTimeFilter: state => state.ui.dateTimeFilter,
      fieldSearchResult: state => state.ui.fieldSearchResult,
      openSubSection: state => state.ui.openSubSection,
      openSubSectionFieldId: state => state.ui.openSubSectionFieldId,
      mapIdsForDownload: state => state.mapIdsForDownload
    }),
    ...mapState('dataTransfer', {
      selectedApplicationMapId: state => state.selectedApplicationMapId
    }),
    ...mapGetters('fieldRecordSystem', [
      'fieldId',
      'fieldsForCurrentOrgUnit',
      'orgUnitId',
      'harvestYear'
    ]),
    ...mapGetters('fieldRecordSystem/mapManagement', [
      'mapsByFieldIdByType'
    ]),
    ...mapGetters('fieldRecordSystem/cultivationPlanning', [
      'cultivationsByFieldId'
    ]),
    previousPage () {
      return this.$route.params.fieldId ? 'fieldDashboard' : 'orgDashboard'
    },
    showChangeMapNameModal: {
      get () {
        return this.newMapName !== null
      },
      set (value) {
        if (!value) {
          this.cancelRename()
        }
      }
    },
    showChangeHeterogenityModal: {
      get () {
        return this.heterogenity !== undefined
      },
      set (value) {
        if (!value) {
          this.cancelHeterogenityChange()
        }
      }
    },
    selectedMapIds () {
      return this.selectionMode === 'single' ? [this.selectedApplicationMapId] : this.mapIdsForDownload
    },
    filteredSoilMaps () {
      const fieldIds = Object.keys(this.mapsByFieldIdByType.soilMap)

      const filteredSoilMaps = {}

      for (const fieldId of fieldIds) {
        filteredSoilMaps[fieldId] = this.mapsByFieldIdByType.soilMap[fieldId]
          .filter(makeMapTypeFilter(this.mapTypeFilter))
      }

      return filteredSoilMaps
    },
    filteredApplicationMaps () {
      const fieldIds = Object.keys(this.mapsByFieldIdByType.applicationMap)

      const filteredApplicationMaps = {}

      for (const fieldId of fieldIds) {
        filteredApplicationMaps[fieldId] = this.mapsByFieldIdByType.applicationMap[fieldId]
          .filter(makeCropUsageFilter(this.cropUsageFilter, this.cropUsageLookup))
          .filter(makeModuleFilter(this.moduleFilter))
          .filter(makeDateFilter(this.dateTimeFilter))
      }

      return filteredApplicationMaps
    },
    filteredFieldLookup () {
      const filteredFields = Object.values(this.mapsByFieldIdByType.fieldBorder).reduce((acc, cur) => acc.concat(cur))
      .filter(field => this.fieldSearchResult.length ? this.fieldSearchResult.includes(field.id) : true)
      .filter(makeCropUsageFilterForField(this.cropUsageByFieldFilter, {...this.cultivationsByFieldId}))

      const filteredFieldLookup = filteredFields
      .map(field => ({
        [field.id]: [field]
      })).reduce(toObject, {})

      return filteredFieldLookup
    }
  },
  methods: {
    ...mapMutations('fieldRecordSystem/mapManagement/soilMapImport', [
      'setMapType'
    ]),
    ...mapMutations('fieldRecordSystem/mapManagement', [
      'clearSelection',
      'toggleSubSection'
    ]),
    ...mapMutations('dataLoading', [
      'invalidate'
    ]),
    ...mapActions('fieldRecordSystem/mapManagement', [
      'cancelRename',
      'cancelHeterogenityChange',
      'loadAvailableZoneMaps',
      'loadAllMaps',
      'loadAvailableZoneMapInfosForOrgUnitId'
    ]),
    ...mapActions('fieldRecordSystem/map', [
      'focus'
    ]),
    ...mapActions('fieldRecordSystem/mapManagement/zoneMapImport', [
      'startZoneMapImport'
    ]),
    ...mapActions('fieldRecordSystem/mapManagement/soilMapImport', [
      'startSoilMapImport'
    ]),
    ...mapActions('fieldRecordSystem/cultivationPlanning', [
      'reloadCultivationsForOrgUnit'
    ]),
    setFieldBorders (data) {
      this.fieldBorders = data
    },
    clearSelectionAndFocus () {
      if (this.selectionMode === 'single' && this.mapSelection) {
        this.clearSelection()
        if (this.fieldId) {
          const geometry = this.fieldData[this.fieldId].simplifiedGeometry
          if (geometry) {
            this.focus(geometry)
          }
        } else {
          const geometries = this.fieldsForCurrentOrgUnit
            .map(field => this.fieldData[field.id].simplifiedGeometry)
            .filter(x => x)

          if (geometries.length) {
            this.focus(geometries)
          }
        }
      }
    },
    refreshZoneMaps () {
      if (this.fieldId) {
        this.invalidate('zoneMaps.forField')
        this.loadAvailableZoneMaps(this.fieldId)
      } else {
        this.invalidate('zoneMapInfos.forOrgUnit')
        this.loadAvailableZoneMapInfosForOrgUnitId(this.orgUnitId)
      }
    },
    refreshAllMaps () {
      Object.keys(this.requests).forEach(key => {
        this.invalidate(key)
      })

      this.loadAllMaps()
    }
  },
  watch: {
    openSection: 'clearSelectionAndFocus',
    openSubSection: 'clearSelectionAndFocus',
    openSubSectionFieldId: 'clearSelectionAndFocus'
  },
  mounted () {
    smarterGet('/api/v2/map-management/orgunits/{orgUnitId}/cultivations/cropUsageIds/{harvestYear}', {
      id: 'mapManagement.cultivation.loadDetails',
      inputs: {
        orgUnitId: () => this.orgUnitId,
        harvestYear: () => this.harvestYear
      },
      onResult: cropUsageIdsByCultivationIdsLookup => {
        this.cropUsageLookup = cropUsageIdsByCultivationIdsLookup
      }
    })

    this.reloadCultivationsForOrgUnit()
  }
}
</script>

<style lang="scss" scoped>
.map-management {
  position: relative;
  flex: 1;
  min-height: 0; // NOTE for firefox
  padding: 4px;

  display: flex;
  flex-direction: column;

  .map-sections {
    flex: 1;
    overflow-y: auto;
  }

  .button-container {
    padding-top: 0.5em;
    border-top: 1px solid lightgray;

    ::v-deep .alert {
      margin-bottom: 10px;
    }
    > * {
      margin-right: 5px;
    }
  }
}

.text {
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  margin-top: 0.2em;
  font-weight: bold;
}
</style>

<template lang="html">
  <div>
    <IxVectorLayer
      :features="nutrientMapFeatures"
      :vector-style="styles.nutrientMap"
      :z-index="1"
      layer-id="nutrientMap"
      auto-focus
    />
    <IxVectorLayer
      :features="nutrientClassificationFeatures"
      :vector-style="styles.classification"
      :z-index="2"
      layer-id="points"
    />
  </div>
</template>

<script>
import {mapResources} from '@helpers/vuex'

import IxVectorLayer from '@components/map/IxVectorLayer'

import NutrientMapDisplayMixin from '@frs/components/map-management/details/NutrientMapDisplayMixin'

export default {
  components: {
    IxVectorLayer
  },
  mixins: [
    NutrientMapDisplayMixin
  ],
  props: {
    map: Object,
    fieldId: String,
    showClassifications: Boolean
  },
  computed: {
    ...mapResources([
      '@frs.SR_FieldRecordSystem'
    ])
  }
}
</script>

<style lang="scss" scoped>
</style>

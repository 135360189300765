<template lang="html">
  <LabelAndMessage :label="label" :description="description">
    <slot slot="description" name="description" />
    <slot slot="label" name="label" />
    <div
      class="file-drop-area" :class="{'error': wrongFormat, 'highlight': impendingDrop}"
      @click="onClick" @dragover="onDragOver"
      @drop="onDrop"
      @dragenter="onDragEnter" @dragleave="onDragLeave"
    >
      <template v-if="wrongFormat">
        <i class="fa fa-close fa-5x" />
        <span><IxRes>Common.SR_Common.IncorrectFileOrFormat</IxRes></span>
      </template>
      <template v-else>
        <i v-if="value" class="fa fa-check fa-5x" />
        <i v-else class="fa fa-upload fa-5x" />
        <transition name="crossfade" mode="out-in">
          <span :key="hintIndex">{{ hints[hintIndex] }}</span>
        </transition>
      </template>
      <input
        ref="input" style="display: none"
        type="file"
        name="file selection" multiple
        :accept="accept" @change="onFilePickerChange"
      >
    </div>
    <div v-if="value" class="file-list">
      <span v-for="(file, i) in fileArray" :key="i">{{ file.name }}</span>
    </div>
  </LabelAndMessage>
</template>

<script>
import {mapResources} from '@helpers/vuex'
import LabelAndMessage from './input-base/LabelAndMessage'

export default {
  components: {
    LabelAndMessage
  },
  props: {
    value: FileList,
    validate: Function,
    label: String,
    description: Array,
    formatName: String,
    accept: String
  },
  data () {
    return {
      impendingDrop: false,
      wrongFormat: false,
      hintIndex: 0
    }
  },
  computed: {
    ...mapResources([
      '@frs.SR_FieldRecordSystem',
      'Common.SR_Common'
    ]),
    hints () {
      return [
        this.formatName || this.accept,
        this.SR_Common.FileDropArea_Click_hint,
        this.SR_Common.FileDropArea_Drop_hint
      ]
    },
    fileArray () {
      return new Array(this.value.length).fill(null).map((x, i) => this.value[i])
    },
    formatRegex () {
      return new RegExp(this.accept.replace(/[\s,]+/g, '|') + '$')
    }
  },
  methods: {
    onFilePickerChange () {
      this.signalFiles(this.$refs.input.files)
    },
    onClick () {
      if (!this.impendingDrop) {
        this.$refs.input.click()
      }
    },
    signalFiles (files) {
      if ([...files].every(file => this.formatRegex.test(file.name)) && (!this.validate || this.validate(files))) {
        this.$emit('input', files)
      } else {
        this.showError()
      }
    },
    onDragOver (event) {
      event.preventDefault()
      event.dataTransfer.dropEffect = 'move'
    },
    onDragEnter (event) {
      event.preventDefault()
      this.impendingDrop = true
    },
    onDragLeave () {
      this.impendingDrop = false
    },
    onDrop (event) {
      event.preventDefault()
      this.signalFiles(event.dataTransfer.files)
      this.impendingDrop = false
    },
    showError () {
      this.wrongFormat = true
      setTimeout(() => {
        this.wrongFormat = false
      }, 1000)
    }
  },
  watch: {
    value (files) {
      if (!files) {
        this.$refs.input.value = null
      }
    }
  },
  created () {
    this.interval = setInterval(() => {
      this.hintIndex = (this.hintIndex + 1) % this.hints.length
    }, 4000)
  },
  beforeDestroy () {
    clearInterval(this.interval)
  }
}
</script>

<style lang="scss" scoped>

$grey: rgb(214, 214, 214);
$darkerGrey: darken($grey, 10%);
$red: rgb(235, 94, 69);
$green: rgb(110, 195, 99);
$darkerGreen: darken($green, 10%);

.file-drop-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;

  user-select: none;
  cursor: pointer;
  transition: border-color 0.3s, background-color 0.3s;

  height: 175px;
  overflow: hidden;
  border: 5px solid $grey;
  // border-radius: 10px;

  padding: 20px;

  i, span {
    pointer-events: none;
    color: $grey;
    transition: color 0.3s, opacity 0.5s;

    &.fa-check {
      color: $green;
    }
  }

  span {
    font-size: 3rem;
    font-weight: 100;
  }

  &:hover {
    border-color: $darkerGrey;
    background-color: rgba($darkerGrey, 0.3);

    i, span {
      color: $darkerGrey;

      &.fa-check {
        color: $darkerGreen;
      }
    }
  }

  &.error {
    border-color: $red;
    background-color: rgba($red, 0.3);

    i, span {
      color: $red;
    }
  }

  &.highlight {
    border-color: $green;
    background-color: rgba($green, 0.3);

    i, span {
      color: $green;
    }
  }
}

.file-list {
  margin: 10px 0px;
  padding: 5px;
  font-size: 0.7em;

  display: flex;
  flex-direction: column;

  border: 1px solid #ddd;
  max-height: 100px;
  overflow: auto;
}

.crossfade-enter, .crossfade-leave-to {
  opacity: 0;
}
</style>

import WFS from 'ol/format/WFS'
import GeoJSON from 'ol/format/GeoJSON'
import GML2 from 'ol/format/GML2'

import {readAsText} from '@helpers/files'

const handler = (files, epsg = 25832) => {
  const wfsFormat = new WFS()
  const legacyWfsFormat = new WFS({
    gmlFormat: new GML2()
  })
  const geojsonFormat = new GeoJSON()

  return Promise.all(files.map(readAsText)).then(fileContents => {
    return fileContents.reduce((featureCollection, gml) => {
      let features = wfsFormat.readFeatures(gml)

      if (features.some(x => !x.getGeometry())) {
        features = legacyWfsFormat.readFeatures(gml)
      }

      if (features.some(x => !x.getGeometry())) {
        throw new Error('invalid WFS file, could not read GML geometries')
      }

      const geojson = JSON.parse(geojsonFormat.writeFeatures(features, {dataProjection: 'EPSG:4326', featureProjection: `EPSG:${epsg}`}))

      featureCollection.features.push(...geojson.features)

      return featureCollection
    }, {type: 'FeatureCollection', features: []})
  })
}

handler.canHandle = file => /\.gml$/.test(file.name)

export default handler

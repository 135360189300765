export default [
  {
    regions: 'Bayern',
    possibleSystems: 'Gauß-Krüger Zone 4',
    epsg: '31468',
    hint: ''
  },
  {
    regions: '',
    possibleSystems: 'ETRS89-UTM32N',
    epsg: '25832',
    hint: ''
  },
  {
    regions: '',
    possibleSystems: 'ETRS89-UTM33N',
    epsg: '25833',
    hint: ''
  },

  {
    regions: 'Baden-Würtemberg',
    possibleSystems: 'Gauß-Krüger Zone 2',
    epsg: '31466',
    hint: ''
  },
  {
    regions: '',
    possibleSystems: 'Gauß-Krüger Zone 3',
    epsg: '31467',
    hint: ''
  },
  {
    regions: '',
    possibleSystems: 'ETRS89-UTM32N',
    epsg: '25832',
    hint: ''
  },

  {
    regions: 'Brandenburg',
    possibleSystems: 'Gauß-Krüger Zone 3',
    epsg: '31467',
    hint: 'westlicher Zipfel'
  },
  {
    regions: '',
    possibleSystems: 'Gauß-Krüger Zone 4',
    epsg: '31468',
    hint: ''
  },
  {
    regions: '',
    possibleSystems: 'ETRS89-UTM32N',
    epsg: '25832',
    hint: 'westlicher Zipfel'
  },
  {
    regions: '',
    possibleSystems: 'ETRS89-UTM33N',
    epsg: '25833',
    hint: ''
  },

  {
    regions: 'Hessen',
    possibleSystems: 'Gauß-Krüger Zone 2',
    epsg: '31466',
    hint: ''
  },
  {
    regions: '',
    possibleSystems: 'Gauß-Krüger Zone 3',
    epsg: '31467',
    hint: ''
  },
  {
    regions: '',
    possibleSystems: 'ETRS89-UTM32N',
    epsg: '25832',
    hint: ''
  },

  {
    regions: 'Mecklenburg-Vorpommern',
    possibleSystems: 'Gauß-Krüger Zone 3',
    epsg: '31467',
    hint: ''
  },
  {
    regions: '',
    possibleSystems: 'Gauß-Krüger Zone 4',
    epsg: '31468',
    hint: ''
  },
  {
    regions: '',
    possibleSystems: 'ETRS89-UTM32N',
    epsg: '25832',
    hint: ''
  },
  {
    regions: '',
    possibleSystems: 'ETRS89-UTM33N',
    epsg: '25833',
    hint: ''
  },

  {
    regions: 'Niedersachsen',
    possibleSystems: 'Gauß-Krüger Zone 2',
    epsg: '31466',
    hint: ''
  },
  {
    regions: '',
    possibleSystems: 'Gauß-Krüger Zone 3',
    epsg: '31467',
    hint: 'westlicher Zipfel'
  },
  {
    regions: '',
    possibleSystems: 'ETRS89-UTM32N',
    epsg: '25832',
    hint: ''
  },

  {
    regions: 'Nordrhein-Westfalen',
    possibleSystems: 'Gauß-Krüger Zone 2',
    epsg: '31466',
    hint: ''
  },
  {
    regions: '',
    possibleSystems: 'Gauß-Krüger Zone 3',
    epsg: '31467',
    hint: ''
  },
  {
    regions: '',
    possibleSystems: 'ETRS89-UTM32N',
    epsg: '25832',
    hint: ''
  },

  {
    regions: 'Rheinland-Pfalz',
    possibleSystems: 'Gauß-Krüger Zone 2',
    epsg: '31466',
    hint: ''
  },
  {
    regions: '',
    possibleSystems: 'ETRS89-UTM32N',
    epsg: '25832',
    hint: ''
  },

  {
    regions: 'Schleswig-Holstein',
    possibleSystems: 'Gauß-Krüger Zone 2',
    epsg: '31466',
    hint: 'nordwestlicher Zipfel'
  },
  {
    regions: '',
    possibleSystems: 'Gauß-Krüger Zone 3',
    epsg: '31467',
    hint: ''
  },
  {
    regions: '',
    possibleSystems: 'ETRS89-UTM32N',
    epsg: '25832',
    hint: ''
  },

  {
    regions: 'Saarland',
    possibleSystems: 'Gauß-Krüger Zone 2',
    epsg: '31466',
    hint: ''
  },
  {
    regions: '',
    possibleSystems: 'ETRS89-UTM32N',
    epsg: '25832',
    hint: ''
  },

  {
    regions: 'Sachsen',
    possibleSystems: 'Gauß-Krüger Zone 4',
    epsg: '31468',
    hint: ''
  },
  {
    regions: '',
    possibleSystems: 'ETRS89-UTM33N',
    epsg: '25833',
    hint: ''
  },

  {
    regions: 'Sachsen-Anhalt',
    possibleSystems: 'Gauß-Krüger Zone 3',
    epsg: '31467',
    hint: ''
  },
  {
    regions: '',
    possibleSystems: 'Gauß-Krüger Zone 4',
    epsg: '31468',
    hint: ''
  },
  {
    regions: '',
    possibleSystems: 'ETRS89-UTM32N',
    epsg: '25832',
    hint: ''
  },
  {
    regions: '',
    possibleSystems: 'ETRS89-UTM33N',
    epsg: '25833',
    hint: ''
  },

  {
    regions: 'Thüringen',
    possibleSystems: 'Gauß-Krüger Zone 3',
    epsg: '31467',
    hint: ''
  },
  {
    regions: '',
    possibleSystems: 'Gauß-Krüger Zone 4',
    epsg: '31468',
    hint: 'östlicher Zipfel'
  },
  {
    regions: '',
    possibleSystems: 'ETRS89-UTM32N',
    epsg: '25832',
    hint: ''
  },
  {
    regions: '',
    possibleSystems: 'ETRS89-UTM33N',
    epsg: '25833',
    hint: 'östlicher Zipfel'
  }
]

<template>
  <div>
    <div
      class="map-item"
      :class="{selected: isSelected, hovering}"
      @click.stop="toggleSelection({id: map.id, type})"
      @mouseenter="hovering = true"
      @mouseleave="hovering = false"
    >
      <DeletionIndicator
        v-if="scheduledRemovalLookup[map.id]"
        :type="type"
        :scheduled-removal-timestamp="scheduledRemovalLookup[map.id]"
        @undo="abortRemove"
      />
      <div v-else class="item-container">
        <div v-if="selectionActive" class="checkbox-container" @click.stop>
          <BsCheckbox
            v-model="isChecked"
            class="checkbox"
            :disabled="selectionType && selectionType !== type"
            inline
          />
        </div>

        <div class="map-name-container shorten-long-text" :title="map.name" :class="{inactive: ['ManagementZoneMap', 'BiomassMap'].includes(map.category) && !map.active}">
          <span class="map-name">{{ map.name }}</span>
          <small v-if="date">{{ date }}</small>
          <span :title="$i18n.translate('frs.mapManagement.icons.premium')">
            <FontAwesomeIcon v-if="map.origin === 'FieldInfo'" :icon="icons.premium" size="xs" />
          </span>
          <span v-if="(map.originalZoneMapId || map.origin === 'import') && type !== 'soilMap'" :title="$i18n.translate('frs.mapManagement.icons.editMap')">
            <strong class="small"><IxRes>frs.mapManagement.icons.editMap</IxRes></strong>
          </span>
          <span v-if="map.isDemo" :title="$i18n.translate('frs.mapManagement.icons.sales')">
            <strong class="small"><IxRes>frs.mapManagement.icons.sales</IxRes></strong>
          </span>
        </div>
        <MapItemMenu
          v-if="selectionMode === 'single'"
          :type="type"
          :category="map.category"
          :row-selected="hovering"
          :map-active="map.active"
          @changeName="startRename(map.id)"
          @changeHeterogenity="startHeterogenityChange(map.id)"
          @download="download"
          @openSimpleApplicationMaps="openSimpleApplicationMaps"
          @export="setMapId(map.id)"
          @remove="removeMap"
          @showDetails="$emit('mapInfo', map.id)"
          @click.stop
        />
      </div>

      <template v-if="isSelected && map.zones">
        <FrsZoneMapLayer v-if="type === 'zoneMap'" :zone-map-id="map.id" />
        <FrsNutrientMapLayer v-else-if="type === 'nutrientMap'" :map="map" />
        <FrsApplicationMapLayer v-else-if="type === 'applicationMap' && !isLimeApplicationMap" :map="map" />
        <PhBbMapLayer v-else-if="type === 'soilMap' || isLimeApplicationMap" :map="map" auto-focus />
      </template>
      <template v-if="isSelected && isInterpolatedRaster">
        <InterpolationMapLayer :map="map" />
      </template>
      <template v-if="isSelected && type === 'fieldBorder'">
        <IxVectorLayer
          :features="[map.feature]" layer-id="fields"
          :vector-style="styles.field"
          :cluster-style="styles.fieldCluster"
          auto-focus
        />
      </template>
    </div>
    <div v-if="selectedApplicationMapId === map.id && selectionMode === 'single'" class="export-buttons">
      <IxButton large @click="$emit('varioDoc')">
        <img width="50px" src="@images/Partner/fendt_small.png">
      </IxButton>
      <IxButton large @click="$emit('osb')">
        <img width="50px" src="@images/Partner/osb.png">
      </IxButton>
      <IxButton v-if="$can('Use.Nevonex')" large @click="$emit('nevonex')">
        <img width="50px" src="@images/Partner/nevonex.png">
      </IxButton>
      <IxButton large @click="$emit('exatrek')">
        <img width="50px" src="@images/Partner/exatrek.png">
      </IxButton>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions, mapMutations, mapGetters} from 'vuex'
import {mapFormFields, mapResources} from '@helpers/vuex'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {faStar, faCircle, faSquare} from '@fortawesome/free-solid-svg-icons'

import IxVectorLayer from '@components/map/IxVectorLayer'
import FrsApplicationMapLayer from '@frs/components/base/FrsApplicationMapLayer'
import FrsNutrientMapLayer from '@frs/components/base/FrsNutrientMapLayer'
import PhBbMapLayer from '@frs/components/base/PhBbMapLayer'
import FrsZoneMapLayer from '@frs/components/base/FrsZoneMapLayer'
import InterpolationMapLayer from '@frs/components/base/InterpolationMapLayer'
import BsCheckbox from '@components/bootstrap/BsCheckbox'
import IxButton from '@components/IxButton'

import MapItemMenu from './MapItemMenu'
import DeletionIndicator from './DeletionIndicator'
import {field, fieldCluster} from '@frs/map-styles'

import PermissionMixin from '@mixins/PermissionMixin'

export default {
  components: {
    BsCheckbox,
    MapItemMenu,
    DeletionIndicator,
    PhBbMapLayer,
    FrsZoneMapLayer,
    FrsNutrientMapLayer,
    FrsApplicationMapLayer,
    InterpolationMapLayer,
    FontAwesomeIcon,
    IxButton,
    IxVectorLayer
  },
  mixins: [PermissionMixin],
  props: {
    map: Object,
    type: String
  },
  data () {
    return {
      deleteActive: false,
      timeout: null,
      showMap: false,
      hovering: false
    }
  },
  computed: {
    ...mapResources([
      'Common.SR_Common',
      'Enums.SR_Enums'
    ]),
    ...mapFormFields('fieldRecordSystem/mapManagement', [
      'ui.selection',
      'ui.mapId'
    ]),
    ...mapState('dataTransfer', {
      selectedApplicationMapId: state => state.selectedApplicationMapId
    }),
    ...mapState('fieldRecordSystem/mapManagement', {
      selectionActive: state => state.ui.selectionActive,
      selectionMode: state => state.ui.selectionMode,
      selectionType: state => state.ui.selectionType,
      scheduledRemovalLookup: state => state.ui.scheduledRemovalLookup,
      mapIdsForDownload: state => state.mapIdsForDownload
    }),
    ...mapGetters('fieldRecordSystem/mapManagement', [
      'fieldIdsByMapId'
    ]),
    date () {
      // fieldBorder has no date
      const mapDate = this.map.generatedAt || this.map.createdAt
      const format = this.map.category === 'InterpolatedSensorData' ? 'long-date' : 'datetime'
      return mapDate ? this.$i18n.format(mapDate, format) : null
    },
    styles () {
      return {
        field, fieldCluster
      }
    },
    isLimeApplicationMap () {
      return this.map.category === 'LimeApplicationMap'
    },
    isInterpolatedRaster () {
      return this.map.category === 'InterpolatedSensorData'
    },
    isChecked: {
      get () {
        return this.mapIdsForDownload.includes(this.map.id)
      },
      set () {
        this.toggleCheckbox({id: this.map.id, type: this.type})
      }
    },
    isSelected () {
      return this.selection ? this.selection.id === this.map.id : false
    },
    icons () {
      return {
        premium: faStar,
        editMap: faCircle,
        sales: faSquare
      }
    }
  },
  methods: {
    ...mapMutations('fieldRecordSystem/mapManagement', [
      'toggleSelection',
      'startRename',
      'scheduleRemoval',
      'unscheduleRemoval',
      'toggleCheckbox',
      'setSelectionType'
    ]),
    ...mapActions('fieldRecordSystem/download', [
      'startDownload'
    ]),
    ...mapActions('fieldRecordSystem/mapManagement', [
      'loadZoneMapDetails',
      'loadNutrientMapDetails',
      'loadApplicationMapDetails',
      'loadSoilMapDetails',
      'loadGeometry',
      'startHeterogenityChange'
    ]),
    ...mapMutations('dataTransfer', [
      'setSelectedApplicationMapId'
    ]),
    setMapId () {
      if (this.selectedApplicationMapId === this.map.id) {
        this.setSelectionType(null)
        this.setSelectedApplicationMapId(null)
      } else {
        this.setSelectionType('applicationMap')
        this.setSelectedApplicationMapId(this.map.id)
      }
    },
    toggleButtonsMenu () {
      if (this.toggledMapMenu === this.map.id) {
        this.toggledMapMenu = null
        return
      }
      this.toggledMapMenu = this.map.id
    },
    download () {
      this.startDownload({type: this.type, ids: [this.map.id]})
    },
    removeMap () {
      this.scheduleRemoval([this.map.id])
    },
    abortRemove () {
      this.unscheduleRemoval([this.map.id])
    },
    openSimpleApplicationMaps () {
      const {params} = this.$route
      if (params.orgUnitId) {
        const fieldId = this.fieldIdsByMapId[this.map.id][0] // a biomass map should not belong to more than one map, so its kind of "safe" to use the first item here
        params.orgUnitId = null
        params.fieldId = fieldId
      }
      this.$router.push({name: 'simpleApplicationMaps', params: {...params, mapId: this.map.id}})
    }
  },
  watch: {
    isSelected (selected) {
      // raster are loaded inside the layer with an url
      if (selected && !this.isInterpolatedRaster) {
        let load

        switch (this.type) {
        case 'zoneMap':
          load = this.loadZoneMapDetails
          break
        case 'nutrientMap':
          load = this.loadNutrientMapDetails
          break
        case 'applicationMap':
          load = this.loadApplicationMapDetails
          break
        case 'soilMap':
          load = this.loadSoilMapDetails
          break
        }
        if (this.type !== 'fieldBorder') {
          load(this.map.id)
        }
      }
    }
  },
  beforeDestroy () {
    if (this.timeout) window.clearTimeout(this.timeout)
  }
}
</script>

<style lang="scss" scoped>
.map-item {
  cursor: pointer;
  padding: 0 0.5em;

  // NOTE stabilize layout because menu is not always visible and takes up more vertical space than MapItem
  min-height: 1.9em;

  &.hovering, &.selected {
    .map-name {
      text-decoration: underline;
      color: #3a74c3;
    }
  }

  &.hovering {
    background-color: #f3f3f3;

    .map-name {
      text-decoration: underline;
      color: #3a74c3;
    }
  }

  &.selected {
    background-color: #e0e0e0;

    .map-name {
      font-weight: bold;
    }
  }
}

.item-container {
  display: flex;
  margin: 1px 0;
}
.checkbox-container {
  width: 6%;
}
.checkbox {
  padding: 0;
  margin: 0;
}
.map-name-container {
  flex: 1;
  margin-top: 3px;
  display: flex;
  align-items: center;

  > * {
    margin-right: 0.25em;
  }
}
.map-name-container.inactive {
  color: lightgray;
}
.shorten-long-text {
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
}

.checkbox ::v-deep input[type="checkbox"] {
  width: 16px;
  height: 16px;
}
.export-buttons {
  display: flex;

  > * {
    margin-right: 5px;
  }
}
</style>

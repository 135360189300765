<template lang="html">
  <div class="filter-container">
    <Search
      v-model="searchText" :data="searchTextOptions"
      :keys="searchKeys"
      :threshold="0.3" @result="onSearchResult"
    >
      <template #label>
        <IxRes>frs.mapManagement.labels.field.search</IxRes>:
      </template>
    </Search>
    <VueSelectize
      v-model="cropUsageByFieldFilter"
      :options="cropUsageOptions"
      :fields="cropUsageFields"
      multiple
      allow-empty
    >
      <template #label>
        <IxRes>frs.mapManagement.labels.culture</IxRes>:
      </template>
    </VueSelectize>
  </div>
</template>
<script>
import {mapMutations, mapGetters} from 'vuex'
import {mapFormFields} from '@helpers/vuex'
import {makeCropUsageFilterForField} from 'src/js/store/modules/field-record-system/map-management/helpers'

import VueSelectize from '@components/VueSelectize'
import Search from '@components/search/Search'

export default {
  components: {
    VueSelectize,
    Search
  },
  data () {
    return {
      searchText: '',
      searchKeys: ['name']
    }
  },
  computed: {
    ...mapFormFields('fieldRecordSystem/mapManagement', [
      'ui.fieldSearchResult',
      'ui.cropUsageByFieldFilter',
      'ui.filterIsLoading'
    ]),
    ...mapGetters('fieldRecordSystem/mapManagement', [
      'mapsByFieldIdByType'
    ]),
    ...mapGetters('fieldRecordSystem/cultivationPlanning', [
      'cultivationsByFieldId'
    ]),
    searchTextOptions () {
      const fields = Object.values(this.mapsByFieldIdByType.fieldBorder).reduce((acc, cur) => acc.concat(cur))

      return fields
        .filter(makeCropUsageFilterForField(this.cropUsageByFieldFilter, this.cultivationsByFieldId))
    },
    cropUsageOptions () {
      const filteredFields = Object.values(this.mapsByFieldIdByType.fieldBorder).reduce((acc, cur) => acc.concat(cur))
      .filter(field => this.fieldSearchResult.length ? this.fieldSearchResult.includes(field.id) : true)
      let cropUsageIds = []

      filteredFields
      .forEach(field => {
        if (this.cultivationsByFieldId[field.id] !== undefined) {
          this.cultivationsByFieldId[field.id].forEach(cult => {
            cropUsageIds.push(cult.cropUsageId)
          })
        } else {
          cropUsageIds.push('noCropUsage')
        }
      })
      let uniqueIds = [...new Set(cropUsageIds)]

      return uniqueIds.filter(x => x != null)
    },
    cropUsageFields () {
      return {
        text: id => this.$i18n.translate(`masterData.crops.usage.${id}_name`),
        value: id => id
      }
    }
  },
  methods: {
    ...mapMutations('fieldRecordSystem/mapManagement', [
      'setFieldSearchResult',
      'removeCropUsageFilter',
      'setFilterValue'
    ]),
    async onSearchResult (value) {
      this.filterIsLoading = true
      await this.$nextTick()
      if (value === null) {
        this.setFieldSearchResult([])
      } else {
        // save search result
        this.setFieldSearchResult(value.map(x => x.id))
      }
      this.filterIsLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.filter-container {
  display: grid;
}
</style>

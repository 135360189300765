<template lang="html">
  <button
    ref="button" :title="title"
    class="btn btn-default"
    :class="{active}" :disabled="disabled"
    @click="onClick"
  >
    <slot />
  </button>
</template>

<script>
/// wrap inside a button-group or don't
export default {
  model: {
    prop: 'toggleValue'
  },
  props: {
    toggleValue: {},
    value: {},
    required: Boolean,
    disabled: Boolean,
    title: String
  },
  computed: {
    active () {
      if (this.toggleValue instanceof Array) {
        return this.toggleValue.includes(this.value)
      } else {
        return this.toggleValue === this.value
      }
    }
  },
  methods: {
    onClick () {
      if (this.toggleValue instanceof Array) {
        if (!this.active) {
          this.$emit('input', this.toggleValue.concat([this.value]))
        } else if (!(this.required && this.toggleValue.length === 1)) {
          this.$emit('input', this.toggleValue.filter(x => x !== this.value))
        }
      } else {
        if (!this.required && this.active) {
          this.$emit('input', null)
        } else {
          this.$emit('input', this.value)
        }
      }
      this.$refs.button.blur()
    }
  }
}
</script>

<style lang="css">
.btn {
  /* padding-top: 4px; */
}
</style>

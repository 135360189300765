import moment from 'moment'

export const makeModuleFilter = moduleFilter => map => moduleFilter ? map.origin === moduleFilter : true

export const makeMapTypeFilter = mapTypeFilter => map => mapTypeFilter ? map.category === mapTypeFilter : true

export const makeDateFilter = dateTimeFilter => map => dateTimeFilter ? moment(map.generatedAt).dayOfYear() === moment(dateTimeFilter).dayOfYear() && moment(map.generatedAt).month() === moment(dateTimeFilter).month() && moment(map.generatedAt).year() === moment(dateTimeFilter).year() : true

export const makeCropUsageFilter = (cropUsageFilter, cropUsageLookup) => map => cropUsageFilter === 'noCropUsage'
  ? map.cultivationId === undefined || (cropUsageLookup && cropUsageLookup[map.cultivationId] === undefined)
  : (cropUsageFilter
    ? map.cultivationId && cropUsageLookup && cropUsageLookup[map.cultivationId] !== undefined && cropUsageLookup[map.cultivationId] === cropUsageFilter
    : true)

export const makeCropUsageFilterForField = (cropUsageFilter, cultivationsByFieldId) => field => {
  if (cropUsageFilter.length === 0) return true

  return cropUsageFilter.some(filterOption => {
    if (filterOption === 'noCropUsage' && !(field.id in cultivationsByFieldId)) {
      return true
    } else if (field.id in cultivationsByFieldId) {
      return cultivationsByFieldId[field.id].map(x => x.cropUsageId).includes(filterOption)
    } else {
      return false
    }
  })
}

<template lang="html">
  <IxVectorLayer
    :features="features" :vector-style="vectorStyle"
    :z-index="1"
    layer-id="zoneMaps"
    auto-focus
  />
</template>

<script>
import {mapToGeoJsonFeatures, getStyle} from '@helpers/map-color-schemes'

import IxVectorLayer from '@components/map/IxVectorLayer'

export default {
  components: {
    IxVectorLayer
  },
  props: {
    zoneMaps: {
      type: Array,
      required: true
    },
    palette: {
      type: String,
      required: true
    }
  },
  computed: {
    features () {
      return this.zoneMaps.reduce((features, zoneMap) => {
        if (!zoneMap || !zoneMap.zones) return features

        features.push(...mapToGeoJsonFeatures(zoneMap, this.palette))

        return features
      }, [])
    },
    vectorStyle () {
      return getStyle(this.palette)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>

<template>
  <IxImageLayer :url="url" :extent="extent" />
</template>

<script>
import IxImageLayer from '@components/map/IxImageLayer'

export default {
  components: {
    IxImageLayer
  },
  props: {
    map: {
      required: true
    }
  },
  computed: {
    extent () {
      return [this.map.bottomLeftEasting, this.map.bottomLeftNorthing, this.map.upperRightEasting, this.map.upperRightNorthing]
    },
    url () {
      return `/api/v2/map-management/interpolation-maps/image/${this.map.id}`
    }
  }
}
</script>

<style lang="scss" scoped>
</style>

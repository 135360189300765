<template>
  <div v-if="debug" class="debug-info">
    <p><strong>URL: </strong>{{ url }}</p>
  </div>
</template>

<script>
import ImageLayer from 'ol/layer/Image'
import Static from 'ol/source/ImageStatic'
import {transformExtent} from 'ol/proj'

export default {
  inject: [
    'getMap'
  ],
  props: {
    url: String,
    extent: Array,
    debug: Boolean
  },
  data () {
    return {
      myLayer: null,
      myMap: null
    }
  },
  methods: {
    recreate () {
      if (this.myLayer) {
        this.myMap.getLayers().remove(this.myLayer)
        this.myLayer = null
      }

      const layer = new ImageLayer({opacity: 0.7})
      const source = new Static({
        url: this.url,
        projection: 'EPSG:4326',
        imageExtent: this.extent
      })
      layer.setSource(source)

      this.myMap.getLayers().push(layer)
      this.myLayer = layer

      this.focus()
    },
    focus () {
      const size = this.myMap.getSize()
      const extent = transformExtent(this.extent, 'EPSG:4326', 'EPSG:3857')
      this.myMap.getView().fit(extent, {size, duration: 500})
    }
  },
  watch: {
    url (value) {
      this.recreate()
    }
  },
  created () {
    this.getMap().then(map => {
      this.myMap = map

      this.recreate()
    })
  },
  beforeDestroy () {
    if (this.myLayer) {
      this.myMap.getLayers().remove(this.myLayer)
    }
  }
}
</script>

<style lang="scss" scoped>
.debug-info {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  border: 1px solid red;
  background-color: lighten(red, 40%);
}
</style>

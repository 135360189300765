<template lang="html">
  <div class="zonemap-list" :class="groupByFields ? 'group-by-fields' : ''">
    <MapItem
      v-for="map in maps"
      :key="map.id"
      :map="map"
      type="nutrientMap"
      @remove="remove(map.id)"
      @mapInfo="showMapInfo(map.id)"
    />
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'

import MirrorRouteMixin from '@frs/mixins/MirrorRouteMixin'

import MapItem from './widgets/MapItem'

export default {
  components: {
    MapItem
  },
  mixins: [
    MirrorRouteMixin
  ],
  props: {
    maps: Array
  },
  computed: {
    ...mapState('fieldRecordSystem/mapManagement', {
      groupByFields: state => state.ui.groupByFields
    })
  },
  methods: {
    ...mapActions('fieldRecordSystem/mapManagement', [
      'deleteZoneMaps',
      'deleteMaps'
    ]),
    remove (id) {
      this.deleteMaps({mapIds: [id], type: 'nutrientMap'})
    },
    showMapInfo (mapId) {
      this.$router.push({name: this.createParallelRoute('mapDetails'), params: {mapId, mapType: 'nutrient-maps'}})
    }
  }
}
</script>

<style lang="scss" scoped>
.zonemap-list {
  display: flex;
  flex-direction: column;
}
.group-by-fields {
  margin-left: 16px;
}
</style>

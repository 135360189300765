<template lang="html">
  <div class="map-section-wrapper">
    <div class="map-section-controls">
      <slot name="controls" :maps="allMaps" />
    </div>

    <template v-if="fieldId || !groupByFields">
      <slot name="list" :maps="allMaps" />
    </template>
    <template v-else>
      <template v-for="field in sortedFields">
        <div v-if="(mapsByFieldId[field.id] && mapsByFieldId[field.id].length) || showFieldsWithoutMaps" :key="field.id">
          <MapSectionHeader :maps="mapsByFieldId[field.id]" :count="mapsByFieldId[field.id] ? mapsByFieldId[field.id].length : 0" :selected-count="selectedCountByFieldId[field.id]">
            <span v-if="field.id !== 'orphaned'" class="field-name">{{ field.name }}</span>
            <IxRes v-else>frs.mapManagement.headers.orphanedMaps</IxRes>
          </MapSectionHeader>

          <slot
            v-if="mapsByFieldId[field.id] && mapsByFieldId[field.id].length"
            name="list"
            :maps="mapsByFieldId[field.id]"
            :field-id="field.id"
          />
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import {sortBy, uniqBy} from 'lodash'
import {mapState, mapGetters} from 'vuex'

import MapSectionHeader from './MapSectionHeader'

export default {
  components: {
    MapSectionHeader
  },
  props: {
    mapsByFieldId: Object
  },
  computed: {
    ...mapState('fieldRecordSystem/mapManagement', {
      selectionMode: state => state.ui.selectionMode,
      mapIdsForDownload: state => state.mapIdsForDownload,
      groupByFields: state => state.ui.groupByFields,
      showFieldsWithoutMaps: state => state.ui.showFieldsWithoutMaps,
      openSelection: state => state.ui.openSection
    }),
    ...mapGetters('fieldRecordSystem', [
      'fieldId'
    ]),
    ...mapGetters('fieldRecordSystem/mapManagement', [
      'relevantFields'
    ]),
    allMaps () {
      const concatMaps = (maps, field) => maps.concat(this.mapsByFieldId[field.id])
      return uniqBy(this.sortedFields.reduce(concatMaps, []), 'id').filter(x => x !== undefined)
    },
    sortedFields () {
      return sortBy(this.relevantFields, 'name')
        // TODO add proper way to handle orphaned maps, without fake field
        .concat({id: 'orphaned', name: 'orphaned'})
    },
    selectedCountByFieldId () {
      return this.groupByFields && this.selectionMode
        ? this.sortedFields.reduce((lookup, field) => {
          lookup[field.id] = this.mapsByFieldId[field.id].filter(x => this.mapIdsForDownload.includes(x.id)).length
          return lookup
        }, {})
        : {}
    }
  },
  methods: {
    getSelectedCount (maps) {
      return maps.filter(x => this.mapIdsForDownload.includes(x.id)).length
    }
  }
}
</script>

<style lang="scss" scoped>
.map-section-wrapper {
  margin: -5px;
}
.map-section-controls {
  padding: 0.5em;
  background-color: #e9e6e6;
}
.field-name {
  font-size: 1.2em;
  font-weight: bold;
}
</style>

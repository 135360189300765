import axios from 'axios'

export default function convertServerSide (files, endpoint, epsg) {
  let bodyFormData = new FormData()
  bodyFormData.set('epsg', epsg)

  const fileArray = [...files]

  fileArray.forEach(file => {
    bodyFormData.append('files', file)
  })

  return axios.post(endpoint, bodyFormData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  .then(response => response.data)
}

<template lang="html">
  <BsModal v-model="modelProxy" :loading="saving">
    <template #title>
      <IxRes>dataTransfer.modals.varioDoc.login.title</IxRes>
    </template>

    <TextInput v-model="username">
      <template #label>
        <IxRes>dataTransfer.modals.varioDoc.userName</IxRes>
      </template>
    </TextInput>
    <TextInput v-model="password" type="password">
      <template #label>
        <IxRes>dataTransfer.modals.varioDoc.password</IxRes>
      </template>
    </TextInput>

    <div class="help-box">
      <HelpBox v-if="noMachineData || wrongLoginData" :type="helpBoxType">
        <IxRes v-if="noMachineData">dataTransfer.modals.varioDoc.login.warning</IxRes>
        <IxRes v-else>dataTransfer.modals.varioDoc.login.error</IxRes>
      </HelpBox>
    </div>

    <template #footer>
      <IxButton cancel large @click="modelProxy = false" />
      <IxButton
        large save
        :disabled="buttonDisabled"
        @click="login"
      >
        <IxRes>dataTransfer.modals.varioDoc.login</IxRes>
      </IxButton>
    </template>
  </BsModal>
</template>

<script>
import {mapActions} from 'vuex'
import {mapFormFields, modelProxy} from '@helpers/vuex'
import {notifications} from 'src/js/infrastructure'

import BsModal from '@components/bootstrap/BsModal'
import TextInput from '@components/forms/TextInput'
import IxButton from '@components/IxButton'
import IxRes from '@components/IxRes'

import HelpBox from '@components/help/HelpBox'

export default {
  components: {
    BsModal,
    TextInput,
    IxRes,
    IxButton,
    HelpBox
  },
  props: {
    value: Boolean
  },
  data () {
    return {
      saving: false,
      noMachineData: false,
      wrongLoginData: false
    }
  },
  computed: {
    modelProxy,
    ...mapFormFields('dataTransfer/varioDoc', [
      'ui.username',
      'ui.password',
      'ui.machineIds'
    ]),
    buttonDisabled () {
      if (this.username && this.password) {
        return false
      }
      return true
    },
    helpBoxType () {
      if (this.noMachineData) {
        return 'warning'
      }
      return 'danger'
    }
  },
  methods: {
    ...mapActions('dataTransfer/varioDoc', [
      'getMachineIds'
    ]),
    async login () {
      this.saving = true
      try {
        await this.getMachineIds()

        if (this.machineIds.length === 0) {
          this.noMachineData = true
          notifications.warning(this.$i18n.translate('dataTransfer.modals.varioDoc.login.warning'))
          return
        }

        this.modelProxy = false
        this.$emit('login')
      } catch {
        console.error('login failed')
        this.wrongLoginData = true
        notifications.error(this.$i18n.translate('dataTransfer.modals.varioDoc.login.error'))
      } finally {
        this.saving = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.help-box {
  margin-top: 10px;
}
</style>

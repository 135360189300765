<template lang="html">
  <div class="batch-button-component">
    <div>
      <IxButton
        v-if="selectionMode === 'single'" large
        icon="download"
        @click="startBatchDownload">
          <IxRes>buttons.enterMultipleMapSelection</IxRes>
      </IxButton>
      <IxButton
        v-else
        cancel large
        @click="finishBatchOperation" />
    </div>
    <div v-if="selectionMode === 'download'">
      <div class="button-group">
        <IxButton
          large
          icon="download"
          :disabled="!mapIdsForDownload.length || !downloadAllowed"
          @click="executeBatchDownload"
        >
          <IxRes :context="{0: mapIdsForDownload.length}">
            buttons.confirmBatchDownload
          </IxRes>
        </IxButton>
        <IxButton
          v-if="selectionType === 'applicationMap' && !fieldId"
          large
          @click="$router.push({name: 'productSummaryOrg'})"
        >
          <IxRes>buttons.productSummary</IxRes>
        </IxButton>
        <IxButton
          :disabled="!downloadAllowed"
          large
          @click="openOsbModal"
        >
          <img width="35px" src="@images/Partner/osb.png">
        </IxButton>
        <IxButton
          :disabled="!downloadAllowed"
          large
          @click="openVarioDocLoginModal"
        >
          <img width="50px" src="@images/Partner/fendt_small.png">
        </IxButton>
        <MapPrint
          v-if="selectionMode === 'download'" :disabled="!printingAllowed"
          :map-ids="mapIdsForDownload" :map-type="selectionType || 'applicationMap'" />
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions, mapMutations, mapGetters} from 'vuex'

import IxButton from '@components/IxButton'
import MapPrint from '@frs/components/map-management/MapPrint'

import PermissionMixin from '@mixins/PermissionMixin'

export default {
  translationNamespace: 'frs.mapManagement',
  components: {
    IxButton,
    MapPrint
  },
  mixins: [
    PermissionMixin
  ],
  computed: {
    ...mapState('fieldRecordSystem/mapManagement', {
      selectionMode: state => state.ui.selectionMode,
      mapIdsForDownload: state => state.mapIdsForDownload,
      openSection: state => state.ui.openSection,
      selectionType: state => state.ui.selectionType,
      mapLookup: state => state.data.mapLookup
    }),
    ...mapGetters('fieldRecordSystem', [
      'fieldId'
    ]),
    ...mapGetters('fieldRecordSystem/mapManagement', [
      'mapLookup'
    ]),
    downloadAllowed () {
      return !this.isApplicationMap || this.hasPermissionToDownloadApplicationMaps
    },
    isApplicationMap () {
      return this.selectionType === 'applicationMap'
    },
    hasPermissionToDownloadApplicationMaps () {
      return this.$can('feature/application-map-download')
    },
    printingAllowed () {
      return this.isPrintableCategory && this.isPrintableType
    },
    isPrintableCategory () {
      return this.printableCategories.includes(this.selectionType)
    },
    isPrintableType () {
      return this.mapIdsForDownload.every(id => this.mapLookup[id].category !== 'LimeApplicationMap')
    },
    printableCategories () {
      return ['applicationMap', 'zoneMap', 'biomassMap', 'nutrientMap']
    }
  },
  methods: {
    ...mapMutations('fieldRecordSystem/mapManagement', [
      'startBatchDownload',
      'finishBatchOperation'
    ]),
    ...mapActions('fieldRecordSystem/mapManagement', [
      'executeBatchDownload'
    ]),
    openVarioDocLoginModal () {
      this.$emit('varioDoc')
    },
    openOsbModal () {
      this.$emit('osb')
    },
    openNevonexModal () {
      this.$emit('nevonex')
    }
  }
}
</script>

<style lang="scss" scoped>
.batch-button-component {
  display: flex;
  flex-direction: column;

  .button-group {
    display: flex;
  }
}
</style>

<template lang="html">
  <div class="filter-container">
    <label class="label-sort-filter">
      <IxRes>frs.mapManagement.labels.sortBy</IxRes>:
    </label>
    <div class="module-filter">
      <label>
        <IxRes>frs.mapManagement.labels.type</IxRes>:
      </label>
      <div class="filter">
        <VueSelectize
          v-model="mapTypeFilter"
          :options="moduleOptions"
          :fields="moduleFieldConfig"
          allow-empty
        />
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import {mapFormFields} from '@helpers/vuex'

import VueSelectize from '@components/VueSelectize'

export default {
  components: {
    VueSelectize
  },
  computed: {
    ...mapGetters('fieldRecordSystem/mapManagement', [
      'mapsByFieldIdByType'
    ]),
    ...mapFormFields('fieldRecordSystem/mapManagement', [
      'ui.mapTypeFilter'
    ]),
    maps () {
      return Object.values(this.mapsByFieldIdByType.soilMap).flat()
    },
    moduleOptions () {
      const categories = this.maps.map(map => map.category)
      return categories.filter(x => x != null)
    },
    moduleFieldConfig () {
      return {
        text: value => this.$i18n.translate(`frs.mapManagement.headers.mapType_${value}`),
        value: value => value
      }
    }
  },
  methods: {
    ...mapMutations('fieldRecordSystem/mapManagement', [
      'setFilterValue'
    ])
  },
  watch: {
    moduleOptions (options) {
      this.setFilterValue({options, type: 'module'})
    }
  }
}
</script>

<style lang="scss" scoped>
.filter-container {
  display: grid;
  grid-template-columns: 20% 25% 30% 25%;
  grid-gap: 5px 0px;
}
.label-sort-filter {
  grid-column: 1 / span 1;
  grid-row: 2 / span 1;
  display: flex;
  align-items: center;
}
.module-filter {
  grid-column: 2 / span 1;
  grid-row: 2 / span 1;
  display: flex;
  flex-direction: column;
}
.filter {
  width: 90%;
}
</style>

import {mapState, mapGetters, mapActions} from 'vuex'

export default {
  props: {
    interval: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapState('fieldRecordSystem/mapManagement', {
      scheduledRemovalLookup: state => state.ui.scheduledRemovalLookup
    }),
    ...mapGetters('fieldRecordSystem/mapManagement', [
      'fieldIdsByMapId'
    ])
  },
  methods: {
    ...mapActions('fieldRecordSystem/mapManagement', [
      'checkScheduledRemovals',
      'removeMaps'
    ])
  },
  watch: {
    interval: {
      immediate: true,
      handler (interval) {
        if (this._interval) {
          clearInterval(this._interval)
        }
        this._interval = setInterval(() => {
          this.checkScheduledRemovals()
        }, this.interval)
      }
    }
  },
  beforeDestroy () {
    if (this._interval) {
      clearInterval(this._interval)
    }
    const mapIds = Object.keys(this.scheduledRemovalLookup)
    this.removeMaps({mapIds, fieldIdsByMapId: this.fieldIdsByMapId})
  },
  render () {
    return null
  }
}

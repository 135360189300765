<template lang="html">
  <div class="map-section-header">
    <div class="group-info" :class="{large, 'has-maps': !!count}">
      <div class="text">
        <slot />
      </div>
      <MapCount :count="count" :count-of-zone-maps="countOfZoneMapsByFieldId" :selected-count="selectedCount" />
      <div class="info">
        <slot name="info" />
      </div>
    </div>
    <div class="controls">
      <slot name="controls" />
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import MapCount from './MapCount'

import PermissionMixin from '@mixins/PermissionMixin'

export default {
  components: {
    MapCount
  },
  mixins: [
    PermissionMixin
  ],
  props: {
    large: Boolean,
    count: Number,
    selectedCount: Number,
    maps: Array
  },
  computed: {
    ...mapState('fieldRecordSystem/mapManagement', {
      openSelection: state => state.ui.openSection
    }),
    countOfZoneMapsByFieldId () {
      if (this.openSelection === 'zoneMap') {
        return {
          active: this.maps.filter(map => map.active).length,
          inactive: this.maps.filter(map => map.active === false && (map.origin === 'FieldInfo'
            ? this.$can('Create.Frs.ZoneMap')
            : true)).length
        }
      } else {
        return null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.section {
  border-bottom: 1px solid lightgray;
}
.map-section-header {
  margin: 0.5em 0;
}
.group-info {
  display: flex;
  align-items: baseline;

  font-size: 1.6rem;

  padding-left: 0.25em;

  &.large {
    font-size: 1.8rem;

    .text {
      font-weight: bold;
    }
  }

  &.has-maps {
    // border-top: 1px solid lightgray;
    // background-color: #e0e0e0;
    border-bottom: 1px solid lightgray;
  }

  .controls {
    margin-left: auto;

    display: flex;
    align-items: center;
  }
  .info {
    margin-left: 0.5em;

    display: flex;
    align-items: center;
  }
}
</style>

<template lang="html">
  <BsModal v-model="modelProxy" :loading="sending">
    <template #title>
      <IxRes>dataTransfer.modals.varioDoc.machines.title</IxRes>
    </template>

    <VueSelectize
      v-model="selectedMachineIds"
      :options="machineIds"
      allow-empty
      multiple
    >
      <template #label>
        <IxRes>dataTransfer.modals.varioDoc.machines.labels.machinesSelection</IxRes>
      </template>
    </VueSelectize>

    <TextInput v-model="designator">
      <template #label>
        <IxRes>dataTransfer.modals.varioDoc.machines.labels.designator</IxRes>
      </template>
    </TextInput>

    <BsCheckbox v-model="includeFieldBorder">
      <IxRes>Common.SR_Common.Labels.IncludeFieldBorder</IxRes>
    </BsCheckbox>

    <template #footer>
      <IxButton cancel large @click="modelProxy = false" />
      <IxButton
        large save
        :disabled="sendButtonDisabled"
        @click="sendData"
      >
        <IxRes>dataTransfer.modals.varioDoc.machines.footer.send</IxRes>
      </IxButton>
    </template>
  </BsModal>
</template>

<script>
import {mapActions} from 'vuex'
import {modelProxy, mapFormFields} from '@helpers/vuex'
import {notifications} from 'src/js/infrastructure'

import BsModal from '@components/bootstrap/BsModal'
import IxRes from '@components/IxRes'
import VueSelectize from '@components/VueSelectize'
import TextInput from '@components/forms/TextInput'
import IxButton from '@components/IxButton'
import BsCheckbox from '@components/bootstrap/BsCheckbox'

export default {
  components: {
    BsCheckbox,
    BsModal,
    IxRes,
    VueSelectize,
    TextInput,
    IxButton
  },
  props: {
    value: Boolean
  },
  data () {
    return {
      sending: false
    }
  },
  computed: {
    modelProxy,
    ...mapFormFields('dataTransfer/varioDoc', [
      'ui.machineIds',
      'ui.designator',
      'ui.selectedMachineIds',
      'ui.includeFieldBorder'
    ]),
    sendButtonDisabled () {
      if (this.selectedMachineIds.length) {
        return false
      }
      return true
    }
  },
  methods: {
    ...mapActions('dataTransfer/varioDoc', [
      'send'
    ]),
    async sendData () {
      try {
        this.sending = true
        await this.send()
        notifications.success(this.$i18n.translate('dataTransfer.modals.notifications.send.success'))
        this.modelProxy = false
      } catch {
        console.error('VarioDoc send error')
        notifications.error(this.$i18n.translate('dataTransfer.modals.varioDoc.login.error'))
      } finally {
        this.sending = false
      }
    }
  }
}
</script>

<style lang="css" scoped>
</style>

<template lang="html">
  <BsCheckbox
    v-if="selectionActive"
    v-model="selected"
    class="checkbox"
    :disabled="disabled"
    inline
    @click.native.stop
  />
</template>

<script>
import BsCheckbox from '@components/bootstrap/BsCheckbox'

import SectionSelectionMixin from './SectionSelectionMixin'

export default {
  components: {
    BsCheckbox
  },
  mixins: [
    SectionSelectionMixin
  ]
}
</script>

<style lang="scss" scoped>
</style>

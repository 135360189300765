const fieldSuffix = 'Field'
const orgSuffix = 'Org'

export default {
  methods: {
    /**
     used to create a parrallel route, depending on the type of current selected entity.
     if the currently selected entity is a field, the method returns a string with name of the route and an appended field suffix,
     otherwise it returns the name of the route with an appended org suffix

     @param route the route can be any route name without an org or field suffix, e.g. mapManagement
     @returns a route with appended suffix for org or field, e.g. 'mapManagementOrg'
    */
    createParallelRoute (route) {
      const fieldId = this.$store.state.fieldRecordSystem.navigation.location.fieldId
      return `${route}${fieldId ? fieldSuffix : orgSuffix}`
    },
    /**
      replaces the entity type suffix from the original route with a suffix for the opposite type.
      eg. if the current route is 'mapManagementOrg' the method returns 'mapManagementField'
    */
    createMirrorRoute (originalRoute) {
      if (originalRoute.endsWith(fieldSuffix)) {
        return originalRoute.replace(fieldSuffix, orgSuffix)
      } else if (originalRoute.endsWith(orgSuffix)) {
        return originalRoute.replace(orgSuffix, fieldSuffix)
      } else {
        throw new Error(`route ${originalRoute} cannot be mirrored because it doesn´t fit in the pattern for mirrorable routes`)
      }
    }
  }
}

<template lang="html">
  <div>
    <Label v-if="!inline && $slots.label" :class="{'font-weight': changedFontWeight}">
      <slot name="label" />
    </Label>
    <div class="toggle-button-group">
      <Label v-if="inline && $slots.label" :class="{'toggle-group-inline-label': inline, 'font-weight': changedFontWeight}">
        <slot name="label" />
      </Label>
      <ToggleButton
        v-for="(button, idx) in toggleButtons" :key="idx"
        v-model="selection"
        :title="button.title" class="btn btn-default"
        :value="button.component" required
      >
        <i v-if="button.icon" :class="button.icon" />
        {{ button.name }}
      </ToggleButton>
    </div>
  </div>
</template>

<script>

import ToggleButton from './ToggleButton'

export default {

  components: {
    ToggleButton
  },

  props: {
    preselection: Boolean,
    toggleButtons: Array,
    inline: Boolean,
    fontWeight: String
  },
  data () {
    return {
      selection: null
    }
  },
  computed: {
    changedFontWeight () {
      return this.fontWeight === 'normal'
    }
  },
  watch: {
    selection (value) {
      this.$emit('viewSelected', value)
    }
  },
  mounted () {
    if (this.toggleButtons && this.preselection) {
      this.selection = this.toggleButtons[0].component
    }
  }
}
</script>

<style lang="scss" scoped>

.toggle-button-group {
  display: flex;
}

.toggle-group-inline-label {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 0.5em;
}

.font-weight {
  font-weight: normal;
}
</style>

<template lang="html">
  <div>
    <IxVectorLayer
      :features="features"
      :vector-style="style"
      :z-index="1"
      layer-id="soilMap"
      :auto-focus="autoFocus"
    />
  </div>
</template>

<script>
import {mapToGeoJsonFeatures} from '@helpers/map-color-schemes'
import IxVectorLayer from '@components/map/IxVectorLayer'
import {phbb} from '@frs/map-styles'

export default {
  components: {
    IxVectorLayer
  },
  props: {
    autoFocus: {
      type: Boolean,
      default: false
    },
    map: Object,
    opacity: {
      type: Number,
      default: 0.85
    }
  },
  computed: {
    features () {
      if (!this.map || this.map.zones === undefined) return []
      if (!Object.keys(this.map).length) return []

      const fts = mapToGeoJsonFeatures(this.map, 'phbb')
      const zoneCount = fts.length

      return fts.map(x => {
        const {value, valueRange, zoneId, zoneMapId, zoneNumber} = x.properties
        return {
          ...x,
          properties: {
            zone: zoneNumber,
            zoneCount,
            value,
            valueRange,
            zoneId,
            zoneMapId,
            displayValue: x.properties.value.toString()
          }
        }
      })
    },
    style () {
      if (!this.map) {
        return
      }
      return phbb(this.opacity, this.map.category)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>

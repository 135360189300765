<template lang="html">
  <div class="org-level-filters">
    <BsCheckbox v-model="groupByFields">
      <IxRes>frs.mapManagement.labels.groupByFields</IxRes>
    </BsCheckbox>
    <BsCheckbox v-if="groupByFields" v-model="showFieldsWithoutMaps">
      <IxRes>frs.mapManagement.labels.showFieldsWithoutMaps</IxRes>
    </BsCheckbox>
  </div>
</template>

<script>
import {mapFormFields} from '@helpers/vuex'

import BsCheckbox from '@components/bootstrap/BsCheckbox'

export default {
  components: {
    BsCheckbox
  },
  computed: {
    ...mapFormFields('fieldRecordSystem/mapManagement', [
      'ui.groupByFields',
      'ui.showFieldsWithoutMaps'
    ])
  }
}
</script>

<style lang="scss" scoped>
.org-level-filters {
  border-bottom: 1px solid lightgray;
}
</style>

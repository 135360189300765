<template lang="html">
  <BsModal v-model="modelProxy" :loading="saving" saving>
    <template #title>
      <IxRes>Common.SR_Common.ChangeHeterogentity</IxRes>
    </template>

    <VueSelectize
      v-model="heterogenity"
      :options="heterogenityOptions"
      :fields="heterogenityFields"
      required allow-empty
    >
      <IxRes>Common.SR_Common.Heterogenities</IxRes>
    </VueSelectize>

    <IxButton
      slot="footer" large
      class="btn btn-success"
      @click="save"
    >
      <IxRes>Common.SR_Common.Confirm</IxRes>
    </IxButton>
    <IxButton
      slot="footer" large
      class="btn btn-danger"
      @click="modelProxy = false"
    >
      <IxRes>Common.SR_Common.Cancel</IxRes>
    </IxButton>
  </BsModal>
</template>

<script>
import {mapActions} from 'vuex'

import {modelProxy, mapFormFields} from '@helpers/vuex'

import {notifications} from 'src/js/infrastructure'
import heterogenities from '@helpers/heterogenities'

import BsModal from '@components/bootstrap/BsModal'
import IxButton from '@components/IxButton'
import VueSelectize from '@components/VueSelectize'

export default {
  components: {
    BsModal,
    IxButton,
    VueSelectize
  },
  props: {
    value: Boolean
  },
  data () {
    return {
      saving: false
    }
  },
  computed: {
    modelProxy,
    ...mapFormFields('fieldRecordSystem/mapManagement', {
      'ui.heterogenity': {
        read: x => x || null
      }
    }),
    heterogenityOptions () {
      return Object.keys(heterogenities)
    },
    heterogenityFields () {
      return {
        text: heterogenity => this.$i18n.translate(`Enums.SR_Enums.HeterogenityEnum_${heterogenity}`)
      }
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/mapManagement', [
      'finishHeterogenityChange'
    ]),
    async save () {
      this.saving = true
      try {
        await this.finishHeterogenityChange()
        notifications.success(this.$i18n.translate('Common.SR_Common.HeterogenityChanged'))
      } catch (error) {
        console.error(error)
        notifications.error(this.$i18n.translate('Common.SR_Common.HeterogenityNotChanged'))
      } finally {
        this.saving = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>

<template lang="html">
  <BsModal v-model="modelProxy" :title="title">
    <template v-if="!title" #title>
      <slot name="title">
        <IxRes>Common.SR_Common.GeometryImportModal_title</IxRes>
      </slot>
    </template>
    <div class="content">
      <FileDropArea
        ref="fileDropArea" v-model="files"
        :format-name="formatName" :accept="acceptedExtends"
        :description="[description, descriptionForCount]" :validate="canImport"
      >
        <template #label>
          <IxRes>Common.SR_Common.GeometryImportModal_DropArea_label</IxRes>
        </template>
      </FileDropArea>

      <div class="tiff-details">
        <VueSelectize
          v-model="epsg" label="EPSG-Codes"
          :options="epsgCodes"
          required
          :fields="fields" sort-field="text"
          name="epsg"
        />

        <VueDateTimePicker
          v-model="mappingDate"
          format="L" iso
          name="date" required
        >
          <template #label>
            <IxRes>phbb.upload.input.mappingDate</IxRes>
          </template>
        </VueDateTimePicker>

        <TextInput
          v-model="layerName"
          name="layerName"
          required
          :description="$i18n.translate('phbb.upload.input.layerName')"
        >
          <template #label>
            <IxRes>phbb.upload.input.layerName</IxRes>
          </template>
        </TextInput>

      </div>
      <FrsLoadingIndicator :loading="loading" />
    </div>

    <div slot="footer">
      <IxButton large cancel @click="modelProxy = false" />
      <IxButton
        :disabled="!canImport(files)"
        class="pull-right"
        large @click="onImport"
      >
        <slot>
          <IxRes :resource="SR_Common">
            Import
          </IxRes>
        </slot>
      </IxButton>
    </div>
  </BsModal>
</template>

<script>
import axios from 'axios'
import {mapActions, mapGetters} from 'vuex'
import {modelProxy, mapResources} from '@helpers/vuex'

import moment from 'moment'

import defaultEpsgCodes from 'src/js/data/epsg-codes'
import {notifications} from 'src/js/infrastructure'

import FormPartMixin from '@components/forms/FormPartMixin'
import StandardRulesMixin from '@components/forms/StandardRulesMixin'

import geoTiffHandler from '@components/forms/geometry-import-handlers/geo-tiff'

import IxButton from '@components/IxButton'
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'
import BsModal from '@components/bootstrap/BsModal'

import FileDropArea from '@components/forms/FileDropArea'
import TextInput from '@components/forms/TextInput'
import VueDateTimePicker from '@components/forms/VueDateTimePicker'
import VueSelectize from '@components/VueSelectize'

export default {
  components: {
    IxButton,
    FrsLoadingIndicator,
    BsModal,
    FileDropArea,
    TextInput,
    VueDateTimePicker,
    VueSelectize
  },
  mixins: [
    FormPartMixin,
    StandardRulesMixin
  ],
  props: {
    mapType: String,
    value: Boolean,
    title: String,
    formatName: {
      type: String,
      default: 'GeoTiff'
    },
    acceptedExtends: {
      type: String,
      default: '.tif, .tiff'
    }
  },
  data () {
    return {
      files: null,
      loading: false,
      layerName: 'Value',
      mappingDate: moment(),
      epsg: 25833
    }
  },
  computed: {
    modelProxy,
    ...mapGetters('geometryImport', [
      'epsgCodes'
    ]),
    ...mapGetters('fieldRecordSystem', [
      'orgUnitId',
      'fieldId',
      'harvestYear'
    ]),
    ...mapResources([
      'Common.SR_Common',
      '@frs.SR_FieldRecordSystem'
    ]),
    fields () {
      return {
        label: code => {
          const data = defaultEpsgCodes.find(x => x.code === code)
          return data ? data.name : ''
        }
      }
    },
    description () {
      return this.SR_Common['GeometryImportModal_geoTiff_description']
    },
    descriptionForCount () {
      return this.$i18n.translate('phbb.upload.sensorData.description.count')
    },
    isValid () {
      return this.state === 'success'
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/mapManagement', [
      'loadInterpolationMapsForField'
    ]),
    canImport(files) {
      if (!files) return false

      return files.length === 1 && geoTiffHandler.canHandle(files[0]) && this.isValid
    },
    onImport () {
      this.validate()
      if (!this.isValid) return

      this.loading = true

      try {
        const fileArray = [...this.files]

        let bodyFormData = new FormData()

        bodyFormData.set('epsgCode', this.epsg)
        bodyFormData.set('mappingDate', this.mappingDate)
        bodyFormData.set('layerName', this.layerName)
        bodyFormData.set('file', fileArray[0])
        bodyFormData.set('fieldId', this.fieldId)

        return axios.post(`/api/v2/phbb/sensor-data/upload/geo-tiff/${this.orgUnitId}/${this.harvestYear}`, bodyFormData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((response) => {
          if (response.data.result) {
            notifications.success(this.$i18n.translate('phbb.upload.sensorData.success'))
            this.loadInterpolationMapsForField(this.fieldId)
          }
        })
        .then(() => {
          this.$emit('input', false)
          this.reset()
        })
        .finally(() => {
          this.loading = false
        })
      } catch (error) {
        notifications.error(this.$i18n.translate('phbb.upload.sensorData.error'))
        this.$refs.fileDropArea.showError()

        console.error(error)
      }
    },
    reset () {
      this.files = null
    }
  },
  watch: {
    value (value) {
      if (!value) {
        this.reset()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tiff-details {
  display: grid;
  margin-top: 10px;
  grid-template-columns: 50% 50%;
  grid-gap: 5px;
}

</style>

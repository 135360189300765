<template lang="html">
  <BsModal v-model="modelProxy" :loading="saving" saving>
    <template #title>
      <IxRes>Common.SR_Common.ChangeMapName</IxRes>
    </template>
    <LabelAndMessage>
      <template #label>
        <IxRes>Common.SR_Common.CurrentMapName</IxRes>
      </template>
      <div>
        {{ newMapName }}
      </div>
    </LabelAndMessage>
    <hr>
    <TextInput v-model="newMapName">
      <template #label>
        <IxRes>Common.SR_Common.NewMapName</IxRes>
      </template>
    </TextInput>
    <IxButton
      slot="footer" large
      class="btn btn-success"
      @click="save"
    >
      <IxRes>Common.SR_Common.Confirm</IxRes>
    </IxButton>
    <IxButton
      slot="footer" large
      class="btn btn-danger"
      @click="modelProxy = false"
    >
      <IxRes>Common.SR_Common.Cancel</IxRes>
    </IxButton>
  </BsModal>
</template>

<script>
import {mapActions} from 'vuex'

import {modelProxy, mapFormFields} from '@helpers/vuex'

import {notifications} from 'src/js/infrastructure'

import BsModal from '@components/bootstrap/BsModal'
import LabelAndMessage from '@components/forms/input-base/LabelAndMessage'
import TextInput from '@components/forms/TextInput'
import IxButton from '@components/IxButton'

export default {
  components: {
    BsModal,
    IxButton,
    LabelAndMessage,
    TextInput
  },
  props: {
    value: Boolean
  },
  data () {
    return {
      saving: false
    }
  },
  computed: {
    modelProxy,
    ...mapFormFields('fieldRecordSystem/mapManagement', [
      'ui.newMapName'
    ])
  },
  methods: {
    ...mapActions('fieldRecordSystem/mapManagement', [
      'finishRename',
      'cancelRename'
    ]),
    async save () {
      this.saving = true
      try {
        await this.finishRename()

        notifications.success(this.$i18n.translate('Common.SR_Common.MapNameChanged'))
      } catch (error) {
        console.error(error)
        await this.cancelRename()
        notifications.error(this.$i18n.translate('Common.SR_Common.MapNameNotChanged'))
      } finally {
        this.saving = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>

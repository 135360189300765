<template lang="html">
  <a
    v-if="selectionActive && !disabled && !selected" href="#"
    data-ajax="false"
    @click.prevent.stop="selected = true"
  >
    <IxRes>frs.mapManagement.buttons.selectAll</IxRes>
  </a>
  <a
    v-else-if="selectionActive && !disabled" href="#"
    data-ajax="false"
    @click.prevent.stop="selected = false"
  >
    <IxRes>frs.mapManagement.buttons.deselectAll</IxRes>
  </a>
</template>

<script>

import SectionSelectionMixin from './SectionSelectionMixin'

export default {
  mixins: [
    SectionSelectionMixin
  ]
}
</script>

<style lang="scss" scoped>
a {
  font-size: 0.8em;
  margin-right: 5px;
}
</style>

<template lang="html">
  <BsModal v-model="modelProxy" name="Box">
    <template #title>
      <IxRes>Areas.ApplicationMaps.SR_ApplicationMaps.osb.createTask</IxRes>
    </template>

    <VueSelectize
      v-if="boxOptions"
      v-model="selectedBox" :options="boxOptions"
      :default="boxOptions[0]"
      allow-empty required
      name="boxOptions"
    >
      <template #label>
        <IxRes>Areas.ApplicationMaps.SR_ApplicationMaps.osb.box</IxRes>
      </template>
    </VueSelectize>

    <TextInput v-model="task.name" name="name" required>
      <template #label>
        <IxRes>Areas.ApplicationMaps.SR_ApplicationMaps.osb.name</IxRes>
      </template>
    </TextInput>

    <TextInput
      v-model="task.description" multiline
      :rows="rows" name="description"
    >
      <template #label>
        <IxRes>Areas.ApplicationMaps.SR_ApplicationMaps.osb.description</IxRes>
      </template>
    </TextInput>

    <div class="row">
      <div class="col-md-6">
        <VueDateTimePicker
          :key="'validFromPicker'" v-model="task.validFrom"
          iso name="startDate"
          required
        >
          <template #label>
            <IxRes>Areas.ApplicationMaps.SR_ApplicationMaps.osb.validFrom</IxRes>
          </template>
        </VueDateTimePicker>
      </div>

      <div class="col-md-6">
        <VueDateTimePicker
          :key="'validToPicker'" v-model="task.validTo"
          iso name="endDate"
          :min="task.validFrom"
        >
          <template #label>
            <IxRes>Areas.ApplicationMaps.SR_ApplicationMaps.osb.validTo</IxRes>
          </template>
        </VueDateTimePicker>
      </div>
    </div>

    <template #footer>
      <IxButton cancel large @click="modelProxy = false" />
      <IxButton
        large save
        @click="tryCreateTask()"
      >
        <IxRes>osb.createTask</IxRes>
      </IxButton>
    </template>
  </BsModal>
</template>

<script>
import {mapActions} from 'vuex'
import {modelProxy, mapFormFields} from '@helpers/vuex'

import TextInput from '@components/forms/TextInput'
import VueDateTimePicker from '@components/forms/VueDateTimePicker'
import VueSelectize from '@components/VueSelectize'
import BsModal from '@components/bootstrap/BsModal'
import IxButton from '@components/IxButton'

import FormPartMixin from '@components/forms/FormPartMixin'
import {notifications} from 'src/js/infrastructure'

export default {
  components: {
    TextInput,
    VueSelectize,
    VueDateTimePicker,
    BsModal,
    IxButton
  },
  mixins: [
    FormPartMixin
  ],
  props: {
    value: Boolean,
    applicationMapIds: Array,
    lanePlanIds: Array
  },
  data () {
    return {
      rows: 4
    }
  },
  computed: {
    modelProxy,
    ...mapFormFields('dataTransfer/osb', [
      'boxes',
      'task'
    ]),
    boxOptions () {
      if (!this.boxes.length) return null
      return this.boxes.map(box => {
        return {value: box.id, text: box.id, label: `v${box.version}`}
      })
    },
    selectedBox: {
      get () {
        if (this.boxOptions.length === 0 || this.task.boxId === null) return null

        return this.boxOptions.find(option => option.value === this.task.boxId)
      },
      set (value) {
        this.task.boxId = value.value
      }
    }
  },
  methods: {
    ...mapActions('dataTransfer/osb', [
      'createTask',
      'loadboxes'
    ]),
    async tryCreateTask () {
      this.validate()
      await this.$nextTick()

      if (this.state === 'success') {
        await this.createTask()
          .then(() => {
            this.modelProxy = false
          })
          .then(() => notifications.success(this.$i18n.translate('osb.taskCreated')))
          .catch(() => notifications.error(this.$i18n.translate('osb.taskCreationFailed')))
      }
    }
  },
  created () {
    this.loadboxes()
  },
  mounted () {
    this.task.applicationMapIds = this.applicationMapIds
    this.task.lanePlanIds = this.lanePlanIds
  }
}
</script>

<style lang="scss" scoped>
.osb {
  display: flex;
  flex-direction: column;
}
</style>

<template lang="html">
  <BsModal v-model="modelProxy">
    <template #title>
      <IxRes>dataTransfer.modals.nevonex</IxRes>
    </template>
    <VueSelectize
      v-model="selectedFeature" :options="featureOptions"
      name="selectedFeature" allow-empty
      required
    >
      <template #label>
        <IxRes>dataTransfer.modals.nevonex.feature</IxRes>
      </template>
    </VueSelectize>
    <TextInput
      v-model="organizationId" name="organizationId"
      required
    >
      <template #label>
        <IxRes>dataTransfer.modals.nevonex.organization</IxRes>
      </template>
    </TextInput>

    <VueSelectize
      v-model="selectedDevice" :options="deviceOptions"
      name="selectedDevice" allow-empty
      required
    >
      <template #label>
        <IxRes>dataTransfer.modals.nevonex.devices</IxRes>
      </template>
    </VueSelectize>
    <IxButton
      :disabled="selectedFeature === null || organizationId === ''"
      download large
      @click="requestDevices"
    >
      <IxRes>dataTransfer.modals.nevonex.requestDevices</IxRes>
    </IxButton>

    <template #footer>
      <IxButton
        upload large
        @click="uploadFiles"
      >
        <IxRes>dataTransfer.modals.nevonex.transfer</IxRes>
      </IxButton>
      <IxButton cancel large @click="modelProxy = false" />
    </template>
    <FrsLoadingIndicator :loading="loading" />
  </BsModal>
</template>

<script>
import axios from 'axios'

import {mapState, mapActions} from 'vuex'

import {modelProxy, mapFormFields} from '@helpers/vuex'
import {notifications} from 'src/js/infrastructure'

import FormPartMixin from '@components/forms/FormPartMixin'

import BsModal from '@components/bootstrap/BsModal'
import IxButton from '@components/IxButton'
import TextInput from '@components/forms/TextInput'
import VueSelectize from '@components/VueSelectize'
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'

export default {
  components: {
    BsModal,
    IxButton,
    TextInput,
    VueSelectize,
    FrsLoadingIndicator
  },
  mixins: [FormPartMixin],
  props: {
    value: Boolean,
    applicationMapIds: Array
  },
  data () {
    return {
      loading: false,
      selectedFeature: null,
      organizationId: '',
      selectedDevice: null
    }
  },
  computed: {
    modelProxy,
    ...mapState('dataTransfer/nevonex', [
    ]),
    ...mapFormFields('dataTransfer/nevonex', [
      'features',
      'devices'
    ]),
    featureOptions () {
      return this.features.map(x => ({value: x.id, name: x.name, text: x.name}))
    },
    deviceOptions () {
      return this.devices.map(x => ({value: x.feu_id, name: x.name, text: x.name}))
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/data-transfer/nevonex', [
    ]),
    async requestDevices () {
      if (this.selectedFeature === null || this.organizationId === '') return

      this.loading = true
      try {
        const response = await axios.get(`/api/v2/nevonex/features/${this.selectedFeature.value}/${this.organizationId}/devices`)
        this.devices = response.data
      } catch (error) {
        console.error(error)
        notifications.error(error.response.data.error)
      } finally {
        this.loading = false
      }
    },
    async uploadFiles () {
      this.validate()

      await this.$nextTick()
      if (this.state !== 'success') return

      this.loading = true
      try {
        await axios.post(`/api/v2/nevonex/features/${this.selectedFeature.value}/${this.selectedDevice.value}/send-file/${this.applicationMapIds[0]}`)
        notifications.success('Übertragung erfolgreich')
      } catch (error) {
        console.error(error)
        notifications.error(error.response.data.error)
      } finally {
        this.loading = false
      }
    }
  },
  async created () {
    try {
      this.loading = true
      const response = await axios.get('/api/v2/nevonex/features')
      this.features = response.data
    } catch (error) {
      console.error(error)
      notifications.error(error)
    } finally {
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.device-selection {
  display: flex;
}
</style>

<template lang="html">
  <div class="biomass-map-list" :class="groupByFields ? 'group-by-fields' : ''">
    <MapItem
      v-for="map in sortedMaps"
      :key="map.id"
      :map="map"
      type="zoneMap"
      @openPopover="openPopover"
      @remove="remove(map.id)"
      @mapInfo="showMapInfo(map.id)"
    />
  </div>
</template>

<script>
import {orderBy} from 'lodash'
import {mapState, mapActions} from 'vuex'
import {mapFormFields} from '@helpers/vuex'

import MirrorRouteMixin from '@frs/mixins/MirrorRouteMixin'

import MapItem from './widgets/MapItem'

import PermissionMixin from '@mixins/PermissionMixin'

export default {
  components: {
    MapItem
  },
  mixins: [
    MirrorRouteMixin,
    PermissionMixin
  ],
  props: {
    maps: Array
  },
  computed: {
    ...mapState('fieldRecordSystem/mapManagement', {
      groupByFields: state => state.ui.groupByFields,
      zoneMapSorting: state => state.ui.zoneMapSorting
    }),
    ...mapFormFields('fieldRecordSystem/mapManagement', [
      'ui.showInactiveZoneMaps'
    ]),
    filteredMaps () {
      if (this.showInactiveZoneMaps) {
        return this.maps
      } else {
        return this.maps.filter(map => map.active)
      }
    },
    sortedMaps () {
      switch (this.zoneMapSorting) {
      case 'name': return orderBy(this.filteredMaps, ['name'])
      case 'date': return orderBy(this.filteredMaps, ['generatedAt'], ['desc'])
      default: throw new Error(`invalid sorting method: ${this.zoneMapSorting}`)
      }
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/mapManagement', [
      'deleteMaps'
    ]),
    remove (id) {
      this.deleteMaps({mapIds: [id], type: 'zoneMap'})
    },
    openPopover () {
      console.error('openPopover not yet implemented')
    },
    showMapInfo (mapId) {
      this.$router.push({name: this.createParallelRoute('mapDetails'), params: {mapId, mapType: 'biomass-maps'}})
    }
  }
}
</script>

<style lang="scss" scoped>
.biomass-map-list {
  display: flex;
  flex-direction: column;
}
.group-by-fields {
  margin-left: 16px;
}
</style>

<template lang="html">
  <div class="count" :class="{expanded}">
    <div v-if="type === 'fieldBorder'">
      <IxRes v-if="!count">
        frs.mapManagement.labels.noGeometries
      </IxRes>
      <template v-else>
        <IxRes v-if="selectedCount !== undefined && selectionMode !== 'single'" :context="{0: selectedCount, 1: count}">
          frs.mapManagement.labels.geometryCountWithSelection
        </IxRes>
        <IxRes v-else-if="count === 1" :context="{0: count}">
          frs.mapManagement.labels.geometryCountSingular
        </IxRes>
        <IxRes v-else :context="{0: count}">
          frs.mapManagement.labels.geometryCount
        </IxRes>
      </template>
    </div>
    <div v-else>
      <IxRes v-if="!count">
        frs.mapManagement.labels.noMaps
      </IxRes>
      <template v-else>
        <IxRes v-if="selectedCount !== undefined && selectionMode !== 'single'" :context="{0: selectedCount, 1: count}">
          frs.mapManagement.labels.mapCountWithSelection
        </IxRes>
        <IxRes v-else-if="countOfZoneMaps" :context="{0: countOfZoneMaps.active, 1: countOfZoneMaps.inactive}">
          frs.mapManagement.labels.mapCountWithInactiveZoneMaps
        </IxRes>
        <IxRes v-else-if="count === 1" :context="{0: count}">
          frs.mapManagement.labels.mapCountSingular
        </IxRes>
        <IxRes v-else :context="{0: count}">
          frs.mapManagement.labels.mapCount
        </IxRes>
      </template>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  props: {
    count: Number,
    selectedCount: Number,
    expanded: Boolean,
    countOfZoneMaps: Object,
    type: String
  },
  computed: {
    ...mapState('fieldRecordSystem/mapManagement', {
      selectionMode: state => state.ui.selectionMode,
      openSection: state => state.ui.openSection
    })
  }
}
</script>

<style lang="scss" scoped>
.count {
  margin: 0 0.5em;
  color: #c6c3c3;
  font-size: 0.8em;

  &.expanded {
    color: #868484;
  }
}
</style>

<template lang="html">
  <div class="selection-container">
    <div class="toggle-buttons">
      <ToggleButtonGroup
        :toggle-buttons="toggleButtons"
        preselection
        font-weight="normal"
        inline
        @viewSelected="selectedSorting"
      >
        <template #label>
          <IxRes>frs.mapManagement.labels.sortBy</IxRes>:
        </template>
      </ToggleButtonGroup>
    </div>
    <div class="inactive-zone-maps-cb">
      <BsCheckbox v-if="$slots.checkboxLabel" v-model="showInactiveZoneMaps">
        <slot name="checkboxLabel" />
      </BsCheckbox>
    </div>
  </div>
</template>

<script>
import {mapResources, mapFormFields} from '@helpers/vuex'

import ToggleButtonGroup from '@components/ToggleButtonGroup'
import BsCheckbox from '@components/bootstrap/BsCheckbox'

export default {
  components: {
    ToggleButtonGroup,
    BsCheckbox
  },
  computed: {
    ...mapResources([
      'Common.SR_Common'
    ]),
    ...mapFormFields('fieldRecordSystem/mapManagement', [
      'ui.zoneMapSorting',
      'ui.showInactiveZoneMaps'
    ]),
    sortFieldConfig () {
      return {
        text: value => this.SR_Common[`SortBy${value[0].toUpperCase()}${value.substring(1)}`],
        value: value => value
      }
    },
    toggleButtons () {
      return this.options.map(opt => (
        {
          title: this.$i18n.translate(`frs.mapManagement.toggle.labels.${opt}`),
          name: this.$i18n.translate(`frs.mapManagement.toggle.labels.${opt}`),
          component: opt
        }
      ))
    },
    options () {
      return ['date', 'name']
    }
  },
  methods: {
    selectedSorting (value) {
      this.zoneMapSorting = value
    }
  }
}
</script>

<style lang="scss" scoped>
.selection-container {
  display: flex;
}
.toggle-buttons {
  margin-right: 15px;
}

</style>

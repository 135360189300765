<template lang="html">
  <div>
    <IxVectorLayer
      :features="features"
      :vector-style="style"
      :z-index="1"
      layer-id="applicationMap"
      auto-focus
    />
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {mapToGeoJsonFeatures, mapToStyle} from '@helpers/map-color-schemes'
import IxVectorLayer from '@components/map/IxVectorLayer'

export default {
  components: {
    IxVectorLayer
  },
  props: {
    map: Object
  },
  computed: {
    ...mapState('fieldRecordSystem/mapManagement', {
      applicationMapPalette: state => state.ui.applicationMapPalette
    }),
    features () {
      if (!this.map) return []
      if (!Object.keys(this.map).length) return []

      return mapToGeoJsonFeatures(this.map, this.applicationMapPalette)
    },
    style () {
      return mapToStyle(this.map, this.applicationMapPalette)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>

<template lang="html">
  <BsModal v-model="modelProxy" :title="title">
    <template v-if="!title" #title>
      <slot name="title">
        <IxRes>Common.SR_Common.GeometryImportModal_title</IxRes>
      </slot>
    </template>
    <div class="content">
      <FileDropArea
        ref="fileDropArea" v-model="files"
        :format-name="formatName" :accept="acceptedExtends"
        :description="description" @input="onInput"
      >
        <template #label>
          <IxRes>Common.SR_Common.GeometryImportModal_DropArea_label</IxRes>
        </template>
      </FileDropArea>

      <FrsLoadingIndicator :loading="loading" />

      <template v-if="files">
        <HelpBox v-if="epsgGuessed">
          <IxRes>Common.SR_Common.GeometryImportModal_AutoEpsg_hint</IxRes>
        </HelpBox>
        <HelpBox v-else-if="filesByHandler.shape.length && !epsgGuessed" type="warning">
          <IxRes>Common.SR_Common.GeometryImportModal_NoProjectionFound_hint</IxRes>
        </HelpBox>

        <HelpBox v-if="unsupportedGmlProjection" type="danger">
          <IxRes>Common.SR_Common.GeometryImportModal_GmlProjectionError_hint</IxRes>
        </HelpBox>
        <HelpBox v-if="unsupportedKmlProjection" type="danger">
          <IxRes>Common.SR_Common.GeometryImportModal_KmlProjectionError_hint</IxRes>
        </HelpBox>
        <div class="import-selections">
          <VueSelectize
            v-if="epsgRequired"
            v-model="epsg" label="EPSG-Codes"
            :options="epsgCodes"
            required allow-empty
            :fields="fields" sort-field="text"
            :create="onCreate"
            name="epsg"
          />
          <VueSelectize
            v-if="mapTypes"
            v-model="selectedMapType" name="mapTypes"
            :options="mapTypes" :fields="mapTypeFields"
            required allow-empty
          >
            <template #label>
              <IxRes>frs.mapManagement.soilMapImport.labels.mapType</IxRes>
            </template>
          </VueSelectize>
        </div>

        <HelpBox v-if="invalidFileFormatForMapType" type="danger">
          <IxRes :context="{mapType: this.$i18n.translate(`Common.SR_Common.GeometryImportModal.MapType_${mapType}`), format: 'Shape (.shp)'}">frs.mapManagement.soilMapImport.helpBox.geoTiffImport.wrongMapType</IxRes>
        </HelpBox>

        <div v-if="epsgRequired" class="epsg-table">
          <strong style="color: red;">
            <IxRes>
              frs.geometry.import.epsg.hint
            </IxRes>
          </strong>
          <label><IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.EpsgInformation</IxRes></label>
          <SimpleTable :columns="columns" :data="data" localize-headers />
        </div>
      </template>
    </div>

    <div slot="footer">
      <IxButton large cancel @click="modelProxy = false" />
      <IxButton
        v-if="files" :disabled="!canImport"
        class="pull-right"
        large @click="convert"
      >
        <slot>
          <IxRes :resource="SR_Common">
            Import
          </IxRes>
        </slot>
      </IxButton>
    </div>
  </BsModal>
</template>

<script>
import {mapGetters, mapActions, mapMutations} from 'vuex'

import {map} from '@helpers/objects'
import {modelProxy, mapResources} from '@helpers/vuex'
import defaultEpsgCodes from 'src/js/data/epsg-codes'
import epsgInfo from './epsg-info'

import geometryImportHandlers from './geometry-import-handlers'

import SimpleTable from '@components/table/SimpleTable'
import VueSelectize from '@components/VueSelectize'
import IxButton from '@components/IxButton'
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'
import BsModal from '@components/bootstrap/BsModal'
import HelpBox from '@components/help/HelpBox'

import FileDropArea from './FileDropArea'

export default {
  components: {
    VueSelectize,
    IxButton,
    FrsLoadingIndicator,
    BsModal,
    HelpBox,
    FileDropArea,
    SimpleTable
  },
  props: {
    mapTypes: Array,
    mapType: String,
    title: String,
    value: Boolean,
    formatName: {
      type: String,
      default: 'Shape, WFS/GML, KML'
    },
    acceptedExtends: {
      type: String,
      default: '.gml, .kml, .kmz, .zip, .shp, .dbf, .shx, .prj, .atx, .sbx, .qix, .aih, .cpg, .shp.xml'
    }
  },
  data () {
    return {
      files: null,
      epsg: null,
      epsgGuessed: false,
      loading: false
    }
  },
  computed: {
    modelProxy,
    ...mapGetters('geometryImport', [
      'epsgCodes'
    ]),
    ...mapResources([
      'Common.SR_Common',
      '@frs.SR_FieldRecordSystem'
    ]),
    selectedMapType: {
      get () {
        return this.mapType
      },
      set (mapType) {
        this.$emit('selectedMapType', mapType)
      }
    },
    description () {
      return Object.keys(geometryImportHandlers)
        .map(handler => this.SR_Common[`GeometryImportModal_${handler}_description`])
    },
    unsupportedGmlProjection () {
      return this.filesByHandler.gml.length && this.epsg && !defaultEpsgCodes.find(x => x.code === this.epsg)
    },
    unsupportedKmlProjection () {
      return this.filesByHandler.kml.length && this.epsg && this.epsg !== 4326
    },
    filesByHandler () {
      const files = [...this.files]

      return map(geometryImportHandlers, handler => files.filter(handler.canHandle))
    },
    fields () {
      return {
        label: code => {
          const data = defaultEpsgCodes.find(x => x.code === code)
          return data ? data.name : ''
        }
      }
    },
    epsgRequired () {
      return Object.keys(this.filesByHandler)
        .some(format => {
          const files = this.filesByHandler[format]

          return files.length && (format === 'gml' || format === 'shape')
        })
    },
    invalidFileFormatForMapType () {
      return this.filesByHandler.geoTiff.length && this.mapType === 'soilEstimation'
    },
    requiredProjectionMissing () {
      return (this.epsgRequired && !this.epsg) || this.unsupportedGmlProjection || this.unsupportedKmlProjection
    },
    mapTypeIsValid () {
      // TODO extract to wrapper component
      return !this.mapTypes || (this.mapType && !this.invalidFileFormatForMapType)
    },
    canImport () {
      return this.files && !this.requiredProjectionMissing && this.mapTypeIsValid
    },
    columns () {
      return {
        regions: 'Areas.FieldRecordSystem.SR_FieldRecordSystem.Region',
        possibleSystems: 'Areas.FieldRecordSystem.SR_FieldRecordSystem.PossibleSystems',
        epsg: 'Areas.FieldRecordSystem.SR_FieldRecordSystem.Epsg',
        hint: 'Common.SR_Common.Hint'
      }
    },
    data () {
      return epsgInfo
    },
    mapTypeFields () {
      return {
        text: x => this.$i18n.translate(`Common.SR_Common.GeometryImportModal.MapType_${x}`),
        value: x => x
      }
    }
  },
  methods: {
    ...mapActions('geometryImport', [
      'guessEpsgForShape'
    ]),
    ...mapMutations('fieldRecordSystem/mapManagement/soilMapImport', [
      'setMapType'
    ]),
    ...mapMutations('geometryImport', [
      'addEpsgCode'
    ]),
    onInput (files) {
      const shpFile = [...files].find(x => /.shp$/i.test(x.name))
      const prjFile = [...files].find(x => /.prj$/i.test(x.name))
      const gmlFile = [...files].find(x => /.gml$/i.test(x.name))

      if (shpFile && prjFile) {
        this.guessEpsgForShape(files)
          .then(code => {
            if (code) {
              this.epsg = code
              this.epsgGuessed = true
            }
          })
      } else if (gmlFile) {
        this.epsg = 25832
        this.epsgGuessed = true
      } else {
        this.epsg = null
        this.epsgGuessed = false
      }
    },
    onCreate (input, callback) {
      const code = parseInt(input)
      if (!Number.isNaN(code)) {
        this.addEpsgCode(code)
      }

      callback()

      this.$nextTick().then(() => {
        this.epsg = code
      })
    },
    convert () {
      const requests = Object.keys(this.filesByHandler)
        .filter(format => this.filesByHandler[format].length)
        .map(format => {
          const handler = geometryImportHandlers[format]
          const files = this.filesByHandler[format]

          return handler(files, this.epsg, this.mapType)
        })

      this.loading = true

      return Promise.all(requests)
        .then(responses => {
          return responses.reduce((featureCollection, response) => {
            featureCollection.features.push(...response.features)
            return featureCollection
          }, {type: 'FeatureCollection', features: []})
        })
        .then(geojson => {
          this.$emit('input', false)
          this.$emit('load', geojson)
          this.reset()
        })
        .catch(error => {
          console.error(error)
          this.$refs.fileDropArea.showError()
        })
        .then(() => {
          this.loading = false
        })
    },
    reset () {
      this.epsg = null
      this.files = null
      this.epsgGuessed = false
    }
  },
  watch: {
    value (value) {
      if (!value) {
        this.reset()
      }
    }
  },
  created () {
    this.setMapType(null)
  }
}
</script>

<style lang="scss" scoped>
.epsg-table {
  display: flex;
  flex-direction: column;
  max-height: 250px;
  overflow-y: auto;
}
.import-selections {
  display: flex;
  :first-child {
    margin-right: 5px;
  }
  > * {
    width: 100%;
  }
}
</style>

<template>
  <div class="soil-maps-container">
    <CollapsibleSection
      v-for="(mapSubset, group) in groupedMaps" :key="group"
      class="section-container"
      :value="openSubSection === group"
      :class="groupByFields ? 'group-by-fields' : ''"
      @input="$emit('toggle', group)"
    >
      <template #header>
        <div class="checkbox-container">
          <SectionSelectionCheckbox type="soilMap" :map-ids="mapSubset.map(x => x.id)" />

          <div class="category">
            <IxRes>
              frs.mapManagement.headers.mapType_{{ group }}
            </IxRes>
            <MapCount :count="mapSubset.length" :selected-count="selectedCountsByGroup[group]" :expanded="openSubSection === group" />
          </div>
        </div>
      </template>
      <MapItem
        v-for="map in mapSubset"
        :key="map.id"
        :map="map"
        type="soilMap"
        @remove="deleteMap(map.id)"
        @mapInfo="showMapInfo(map.id)"
      />
    </CollapsibleSection>
  </div>
</template>

<script>
import {mapValues, groupBy, orderBy} from 'lodash'
import {mapState, mapActions} from 'vuex'

import MirrorRouteMixin from '@frs/mixins/MirrorRouteMixin'

import CollapsibleSection from '@components/CollapsibleSection'

import MapItem from './widgets/MapItem'
import MapCount from './widgets/MapCount'
import SectionSelectionCheckbox from './widgets/SectionSelectionCheckbox'

export default {
  components: {
    MapItem,
    MapCount,
    SectionSelectionCheckbox,
    CollapsibleSection
  },
  mixins: [
    MirrorRouteMixin
  ],
  props: {
    maps: Array,
    openSubSection: String
  },
  computed: {
    ...mapState('fieldRecordSystem/mapManagement', {
      groupByFields: state => state.ui.groupByFields,
      mapIdsForDownload: state => state.mapIdsForDownload,
      mapTypeFilter: state => state.ui.mapTypeFilter,
      soilMapsLookup: state => state.data.soilMap.mapLookup
    }),
    groupedMaps () {
      let filteredMaps = this.mapTypeFilter ? this.maps.filter(x => x.category === this.mapTypeFilter) : this.maps
      return groupBy(orderBy(filteredMaps, ['generatedAt'], ['desc']), 'category')
    },
    selectedCountsByGroup () {
      return mapValues(this.groupedMaps, mapSubset => mapSubset.filter(map => this.mapIdsForDownload.includes(map.id)).length)
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/mapManagement', [
      'deleteMaps'
    ]),
    deleteMap (id) {
      this.deleteMaps({mapIds: [id], type: 'soilMap'})
    },
    showMapInfo (mapId) {
      const map = this.soilMapsLookup[mapId]
      this.$router.push({name: this.createParallelRoute('mapDetails'), params: {mapId, mapType: map.category === 'InterpolatedSensorData' ? 'interpolation-maps' : 'soil-maps'}})
    }
  }
}
</script>

<style lang="scss" scoped>
.category {
  display: flex;
  align-items: baseline;
}
.group-by-fields {
  margin-left: 16px;
}
.soil-maps-container {
  display: flex;
  flex-direction :column;
  margin: 0.5em;
}
.header-container {
  display: flex;
}
.label-text {
  font-size: 1.6em;
  margin-right: 20px;
}
.checkbox-container{
  display: flex;
  justify-content: space-between;
}
.checkbox{
  margin: 0;
  padding: 0;
}
.selection-container {
  width: 60%;
}
.checkbox ::v-deep input[type="checkbox"] {
  width: 16px;
  height: 16px;
}
</style>

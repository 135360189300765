<template lang="html">
  <div class="delete-container">
    <div class="delete-item">
      <IxRes>frs.mapManagement.messages.{{ type }}_deleted</IxRes>
      <IxButton class="undo-button" icon="undo" @click="$emit('undo')">
        <IxRes>Common.SR_Common.Undo</IxRes>
      </IxButton>
    </div>
    <div class="delete-timer" :style="{animationDelay}" />
  </div>
</template>

<script>
import IxButton from '@components/IxButton'

export default {
  components: {
    IxButton
  },
  props: {
    type: String,
    scheduledRemovalTimestamp: Number
  },
  computed: {
    animationDelay () {
      const remainingTime = this.scheduledRemovalTimestamp - Date.now()

      return `-${(10 - remainingTime / 1000)}s`
    }
  }
}
</script>

<style lang="scss" scoped>
.delete-container {
  display: flex;
  flex-direction: column;
  height: 1.9em;
}
.delete-item {
  margin-top: 2px;
  flex: 1;
  display: flex;
  justify-content: space-between;
  color: #ff2f3b;
}
.delete-timer {
  animation-name: fill;
  animation-duration: 10s;
  animation-timing-function: linear;
  height: 2px;
}
@keyframes fill {
  from {
    width: 0;
    background-color: #585858;
  }
  to {
    width: 100%;
    background-color: #585858;
  }
}
.undo-button {
  line-height: 1em;
}
</style>

<template lang="html">
  <div class="collapsible-map-section-header">
    <div class="title">
      <slot />
    </div>
    <MapCount
      :type="type"
      :count="count"
      :count-of-zone-maps="countOfZoneMaps"
      :selected-count="selectionMode !== 'single' ? selectedCount : undefined"
      :expanded="expanded"
    />
    <SectionSelectionLink :map-ids="mapIds" :type="type" />
    <slot name="additional-info" />
  </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex'

import MapCount from './MapCount'
import SectionSelectionLink from './SectionSelectionLink'

export default {
  components: {
    MapCount,
    SectionSelectionLink
  },
  props: {
    expanded: Boolean,
    type: String
  },
  computed: {
    ...mapState('fieldRecordSystem', {
      harvestYear: state => state.userSettings.harvestYear
    }),
    ...mapState('fieldRecordSystem/mapManagement', {
      selectionMode: state => state.ui.selectionMode,
      mapLookup: state => state.data
    }),
    ...mapGetters('fieldRecordSystem/mapManagement', [
      'mapsByType',
      'selectedMapsByType'
    ]),
    selectedCount () {
      if (this.type === 'biomassMap' || this.type === 'zoneMap') {
        return this.selectedMapsByType[this.group].filter(map => map.category === this.category).length
      } else {
        return this.selectedMapsByType[this.group].length
      }
    },
    mapIds () {
      if (this.type === 'biomassMap' || this.type === 'zoneMap') {
        return this.mapsByType[this.group].filter(map => map.category === this.category).map(map => map.id)
      } else {
        return this.mapsByType[this.group].map(map => map.id)
      }
    },
    count () {
      return this.mapIds.length
    },
    group () {
      return this.type === 'biomassMap'
        ? 'zoneMap'
        : this.type
    },
    category () {
      return this.type === 'zoneMap'
        ? 'ManagementZoneMap'
        : this.type === 'biomassMap'
          ? 'BiomassMap'
          : this.type
    },
    countOfZoneMaps () {
      if (this.type === 'zoneMap' || this.type === 'biomassMap') {
        return {
          active: this.mapsByType['zoneMap'].filter(map => map.active && map.category === this.category).length,
          inactive: this.mapsByType['zoneMap'].filter(map => !map.active && map.category === this.category).length
        }
      } else {
        return null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.collapsible-map-section-header {
  display: flex;
  align-items: baseline;

  margin-top: 0.2em;

  font-size: 1.8rem;

  .title {
    font-weight: bold;
  }
}
</style>

<template lang="html">
  <ZoneMapsLayer :zone-maps="zoneMaps" :palette="palette" />
</template>

<script>
import {mapState, mapActions} from 'vuex'

import ZoneMapsLayer from '@components/map/layers/ZoneMapsLayer'

export default {
  components: {
    ZoneMapsLayer
  },
  props: {
    zoneMapId: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState('fieldRecordSystem/mapManagement', {
      zoneMapLookup: state => state.data.zoneMap.mapLookup,
      zoneMapPalette: state => state.ui.zoneMapPalette
    }),
    zoneMaps () {
      const zoneMap = this.zoneMapLookup[this.zoneMapId]

      if (!zoneMap || !zoneMap.zones) return []

      return [zoneMap]
    },
    palette () {
      const zoneMap = this.zoneMapLookup[this.zoneMapId]

      if (zoneMap && zoneMap.category === 'BiomassMap') {
        return 'pastel'
      } else {
        return this.zoneMapPalette
      }
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/mapManagement', [
      'loadZoneMapDetails'
    ])
  },
  watch: {
    zoneMapId: {
      immediate: true,
      handler (zoneMapId) {
        if (zoneMapId) {
          this.loadZoneMapDetails(zoneMapId)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>

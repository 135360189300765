<template>
  <div class="application-maps-container">
    <CollapsibleSection
      v-for="(mapSubset, group) in groupedMaps" :key="group"
      class="section-container"
      :value="openSubSection === group"
      :class="groupByFields ? 'group-by-fields' : ''"
      @input="$emit('toggle', group)"
    >
      <template #header>
        <div class="checkbox-container">
          <SectionSelectionCheckbox type="applicationMap" :map-ids="mapSubset.map(x => x.id)" />

          <div class="category">
            <template v-if="applicationMapSorting === 'cultivation'">
              <span v-if="group !== 'null'">
                <IxRes>masterData.crops.usage.{{ group }}_name</IxRes>
              </span>
              <IxRes v-else>
                frs.mapManagement.headers.noCropOrCultivation
              </IxRes>
            </template>
            <IxRes v-else>
              frs.mapManagement.headers.{{ applicationMapSorting }}_{{ group }}
            </IxRes>
            <MapCount :count="mapSubset.length" :selected-count="selectedCountsByGroup[group]" :expanded="openSubSection === group" />
          </div>
        </div>
      </template>
      <MapItem
        v-for="map in mapSubset"
        :key="map.id"
        :map="map"
        type="applicationMap"
        @remove="deleteMap(map.id)"
        @mapInfo="showMapInfo(map.id)"
        @varioDoc="$emit('varioDoc')"
        @osb="$emit('osb')"
        @nevonex="$emit('nevonex')"
        @exatrek="$emit('exatrek')"
      />
    </CollapsibleSection>
  </div>
</template>

<script>
import {mapValues, groupBy, orderBy} from 'lodash'
import {mapState, mapGetters, mapMutations, mapActions} from 'vuex'

import MirrorRouteMixin from '@frs/mixins/MirrorRouteMixin'

import CollapsibleSection from '@components/CollapsibleSection'

import MapItem from './widgets/MapItem'
import MapCount from './widgets/MapCount'
import SectionSelectionCheckbox from './widgets/SectionSelectionCheckbox'

export default {
  components: {
    MapItem,
    MapCount,
    SectionSelectionCheckbox,
    CollapsibleSection
  },
  mixins: [
    MirrorRouteMixin
  ],
  props: {
    maps: Array,
    openSubSection: String,
    cropUsageLookup: Object
  },
  data () {
    return {
      hidemap: {}
    }
  },
  computed: {
    ...mapState('fieldRecordSystem', {
      applicationMaps: state => state.applicationMaps
    }),
    ...mapState('fieldRecordSystem/mapManagement', {
      groupByFields: state => state.ui.groupByFields,
      applicationMapSorting: state => state.ui.applicationMapSorting,
      mapIdsForDownload: state => state.mapIdsForDownload
    }),
    ...mapGetters('fieldRecordSystem', [
      'fieldId'
    ]),
    ...mapGetters('fieldRecordSystem/mapManagement', [
      'productTypeLookup'
    ]),
    groupingCriterium () {
      switch (this.applicationMapSorting) {
      case 'module': return 'origin'
      case 'application': return map => this.productTypeLookup[map.productId]
      case 'cultivation': return map => {
        if (map.cultivationId && this.cropUsageLookup[map.cultivationId] !== undefined) {
          return (map.cultivationId && this.cropUsageLookup[map.cultivationId])
        }
      }
      default: throw new Error(`invalid grouping type: ${this.applicationMapSorting}`)
      }
    },
    groupedMaps () {
      if (!this.applicationMapSorting) return []
      return groupBy(orderBy(this.maps, ['generatedAt'], ['desc']), this.groupingCriterium)
    },
    selectedCountsByGroup () {
      return mapValues(this.groupedMaps, mapSubset => mapSubset.filter(map => this.mapIdsForDownload.includes(map.id)).length)
    }
  },
  methods: {
    ...mapMutations('fieldRecordSystem/mapManagement', [
      'clearSelection'
    ]),
    ...mapActions('fieldRecordSystem/mapManagement', [
      'deleteMaps'
    ]),
    applicationMapSortingHeader (map) {
      if (this.applicationMapSorting === 'module') return map.category
      if (this.applicationMapSorting === 'application') return map.origin
      return map.cultivationId
    },
    deleteMap (id) {
      this.deleteMaps({mapIds: [id], type: 'applicationMap'})
    },
    showMapInfo (mapId) {
      this.$router.push({name: this.createParallelRoute('mapDetails'), params: {mapId, mapType: 'application-maps'}})
    }
  }
}
</script>

<style lang="scss" scoped>
.category {
  display: flex;
  align-items: baseline;
}
.group-by-fields {
  margin-left: 16px;
}
.application-maps-container {
  display: flex;
  flex-direction :column;
  margin: 0.5em;
}
.header-container {
  display: flex;
}
.label-text {
  font-size: 1.6em;
  margin-right: 20px;
}
.checkbox-container{
  display: flex;
  justify-content: space-between;
}
.checkbox{
  margin: 0;
  padding: 0;
}
.selection-container {
  width: 60%;
}
.checkbox ::v-deep input[type="checkbox"] {
  width: 16px;
  height: 16px;
}
</style>

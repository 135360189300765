import GeoJSON from 'ol/format/GeoJSON'
import KML from 'ol/format/KML'
import JSZip from 'jszip'

import {readAsText} from '@helpers/files'

const handler = files => {
  const kmlFormat = new KML()
  const geojsonFormat = new GeoJSON()
  const read = file => /\.kmz$/i.test(file.name)
    ? JSZip.loadAsync(file).then(zip => {
      const kml = zip.file(/\.kml$/i)[0]
      return kml.async('text')
    })
    : readAsText(file)

  return Promise.all(files.map(read)).then(fileContents => {
    return fileContents.reduce((featureCollection, kml) => {
      const validKml = validateKml(kml)
      const features = kmlFormat.readFeatures(validKml)

      features.forEach((feature, index) => {
        const {id} = feature.getProperties()
        if (!id) {
          feature.setProperties({id: `${index + 1}`})
        }
      })

      const geojson = JSON.parse(geojsonFormat.writeFeatures(features))

      featureCollection.features.push(...geojson.features)

      return featureCollection
    }, {type: 'FeatureCollection', features: []})
  })
}

const makeValidCoordinate = coordinate => {
  const correctedCoordinate = parseFloat(coordinate)
  if (!isNaN(correctedCoordinate)) {
    // return only valid numbers
    return correctedCoordinate
  }
}

const makeValidCoordinates = coordinates => coordinates.split(',').map(makeValidCoordinate).join(',')

const validateKml = (kml) => {
  const serializer = new XMLSerializer()
  const parser = new DOMParser()
  const xmlDoc = parser.parseFromString(kml, 'text/xml')

  // get all coordinate elements that contain the geometries
  const coordinates = xmlDoc.getElementsByTagName('coordinates')
  if (coordinates.length <= 0) {
    throw new Error('Kml file has no coordinates to validate!')
  }
  for (let i = 0; i < coordinates.length; i++) {
    const item = coordinates.item(i)
    item.innerHTML = item.innerHTML.trim().split(/\s+/).map(makeValidCoordinates).join(' ')
  }
  return serializer.serializeToString(xmlDoc)
}

handler.canHandle = file => /\.km(l|z)$/.test(file.name)

export default handler

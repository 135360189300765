<template lang="html">
  <BsModal v-model="modelProxy">
    <template #title>
      exatrek
    </template>
    <div class="login-wrapper">
      <LabelAndMessage required>
        <template #label>
          Loginname:
        </template>
        <TextInput v-model="loginName" />
      </LabelAndMessage>
      <LabelAndMessage required>
        <template #label>
          Password:
        </template>
        <TextInput v-model="password" type="password" />
      </LabelAndMessage>
    </div>
    <br>
    <IxButton
      v-if="loginName && password"
      class="login-button"
      large icon="sign-in"
      @click="loadData"
    >
      Organisationseinheiten und Maschinen laden
    </IxButton>
    <hr>
    <LabelAndMessage required>
      <template #label>
        Filename:
      </template>
      <TextInput v-model="fileName" required />
    </LabelAndMessage>
    <VueSelectize v-model="selectedOrgUnit" :options="orgUnits" allow-empty>
      <template #label>
        Organisationseinheit:
      </template>
    </VueSelectize>
    <VueSelectize
      v-model="selectedMachine" :options="machineIds"
      :fields="machineFields"
      allow-empty
    >
      <template #label>
        Maschine:
      </template>
    </VueSelectize>
    <br>
    <div class="button-wrapper">
      <IxButton
        :disabled="!selectedMachine || fileName === ''"
        large upload
        @click="sendFile"
      >
        Senden
      </IxButton>
      <IxButton large cancel @click="modelProxy = false" />
    </div>

    <FrsLoadingIndicator :loading="isLoading" />
  </BsModal>
</template>

<script>
import BsModal from '@components/bootstrap/BsModal'
import {modelProxy} from '@helpers/vuex'
import LabelAndMessage from '@components/forms/input-base/LabelAndMessage'
import TextInput from '@components/forms/TextInput'
import IxButton from '@components/IxButton'
import VueSelectize from '@components/VueSelectize'
import {smarterPost} from '@helpers/vuex/data-loading'
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'
import {notifications} from 'src/js/infrastructure'

export default {
  components: {
    FrsLoadingIndicator,
    VueSelectize,
    IxButton,
    TextInput,
    LabelAndMessage,
    BsModal
  },
  props: {
    value: Boolean,
    applicationMapIds: Array
  },
  data () {
    return {
      loginName: null,
      password: null,
      machinesByOrgUnitLookup: null,
      selectedOrgUnit: null,
      fileName: '',
      selectedMachine: null,
      isLoading: false
    }
  },
  computed: {
    modelProxy,
    orgUnits () {
      if (!this.machinesByOrgUnitLookup) return []
      return Object.keys(this.machinesByOrgUnitLookup)
    },
    machines () {
      if (!this.selectedOrgUnit) return []
      return this.machinesByOrgUnitLookup[this.selectedOrgUnit]
    },
    machineIds () {
      return this.machines.map(machine => machine.id)
    },
    machineFields () {
      return {
        text: id => this.machines.find(machine => machine.id === id).name,
        value: id => id
      }
    }
  },
  methods: {
    async loadData () {
      const data = {
        loginName: this.loginName,
        password: this.password
      }
      this.isLoading = true
      try {
        this.machinesByOrgUnitLookup = await smarterPost('/api/v2/exatrek/data', data, {
          id: 'exatrek.loadData'
        })
      } catch (error) {
        const body = error.response.data
        const message = JSON.parse(body.error).message
        console.error(message)
        notifications.error(message)
      } finally {
        this.isLoading = false
      }
    },
    async sendFile () {
      const data = {
        loginName: this.loginName,
        password: this.password
      }
      this.isLoading = true
      try {
        await smarterPost('/api/v2/exatrek/send-file/{applicationMapId}/to-machine/{machineId}/{fileName}', data, {
          id: 'exatrek.sendFile',
          inputs: {
            applicationMapId: () => this.applicationMapIds[0],
            machineId: () => this.selectedMachine,
            fileName: () => this.fileName
          }
        })
        notifications.success('Übertragung erfolgreich')
      } catch (error) {
        const body = error.response.data
        const message = JSON.parse(body.error).message
        console.error(message)
        notifications.error(message)
      } finally {
        this.isLoading = false
      }
    }
  },
  watch: {
    selectedOrgUnit (value) {
      if (!value) this.selectedMachine = null
    }
  }
}
</script>

<style lang="scss" scoped>
.button-wrapper {
  display: flex;
  justify-content: space-between;
}
</style>

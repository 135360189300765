import {mapActions} from 'vuex'

import parse from 'wellknown'
import {flatten} from '@helpers/reducers'
import {nutrientClassification, nutrientMap} from '@frs/map-styles'
import {mapToGeoJsonFeatures} from '@helpers/map-color-schemes'

import DisableDefaultMapInteractionsMixin from '@frs/mixins/DisableDefaultMapInteractionsMixin'

export default {
  mixins: [
    DisableDefaultMapInteractionsMixin
  ],
  computed: {
    selectedNutrientMaps () {
      return [this.map]
    },
    nutrientClassificationFeatures () {
      if (!this.showClassifications) return []
      return this.selectedNutrientMaps.reduce((features, nutrientMap) =>
        features.concat(
          nutrientMap.classifications !== undefined ? nutrientMap.classifications.map((classification, index) => {
            return {
              type: 'Feature',
              geometry: parse(classification.geometry),
              properties: {
                createdAt: classification.createdAt,
                id: classification.id,
                orgUnitId: classification.orgUnitId,
                probingMethod: classification.probingMethod,
                timestamp: classification.timestamp,
                type: classification.type,
                value: classification.value,
                showZentroid: this.showZentroid,
                measurement: nutrientMap.measurements[index] ? nutrientMap.measurements[index].value : null
              }
            }
          }) : []
        ), []
      )
    },
    nutrientZoneFeatures () {
      const zoneToFeature = ({geometry, value: contentClass}) => ({
        type: 'Feature',
        geometry: parse(geometry),
        properties: {
          contentClass,
          id: this.SR_FieldRecordSystem[`Zone_${contentClass}`]
        }
      })
      const mapToFeatures = nutrientMap => nutrientMap.zones.map(zoneToFeature)

      return this.selectedNutrientMaps.map(mapToFeatures).reduce(flatten, [])
    },
    nutrientMapPlaceholderFeatures () {
      const fieldData = this.$store.state.fieldRecordSystem.data.field

      const fieldIdToPlaceholder = fieldId => (fieldId ? {
        type: 'Feature',
        geometry: parse(fieldData[fieldId].geometry),
        properties: {
          placeholder: 'Keine Nährstoffdaten'
        }
      } : {})

      return fieldIdToPlaceholder(this.fieldId)
    },
    nutrientMapFeatures () {
      return this.nutrientZoneFeatures
    },
    features () {
      if (this.map.zones === undefined) return []

      return mapToGeoJsonFeatures(this.map, 'agrosat')
    },
    styles () {
      return {
        nutrientMap: nutrientMap,
        classification: nutrientClassification
      }
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/map', [
      'focus'
    ])
  }
}

import {mapState, mapMutations} from 'vuex'

export default {
  props: {
    type: String,
    mapIds: Array
  },
  computed: {
    ...mapState('fieldRecordSystem/mapManagement', {
      selectionActive: state => state.ui.selectionActive,
      selectionMode: state => state.ui.selectionMode,
      selectionType: state => state.ui.selectionType,
      mapIdsForDownload: state => state.mapIdsForDownload,
      showInactiveZoneMaps: state => state.ui.showInactiveZoneMaps,
      zoneMapLookup: state => state.data.zoneMap.mapLookup
    }),
    disabled () {
      return !this.visibleMapIds.length || (this.selectionMode === 'download' && this.selectionType && this.selectionType !== this.type)
    },
    visibleMapIds () {
      if ((this.type === 'zoneMap' || this.type === 'biomassMap') && !this.showInactiveZoneMaps) {
        return Object.values(this.zoneMapLookup).filter(map => this.mapIds.includes(map.id)).filter(map => map.active).map(map => map.id)
      } else {
        return this.mapIds
      }
    },
    selected: {
      get () {
        let allSelected = true
        let atLeastOneSelected = false
        for (const id of this.visibleMapIds) {
          if (this.mapIdsForDownload.indexOf(id) === -1) {
            allSelected = false
          } else {
            atLeastOneSelected = true
          }
        }
        if (this.visibleMapIds.length && allSelected) {
          return true
        } else if (atLeastOneSelected) {
          return null
        } else {
          return false
        }
      },
      set (value) {
        if (value) {
          this.selectMultiple({ids: this.visibleMapIds, type: this.type})
        } else {
          this.deselectMultiple({ids: this.visibleMapIds, type: this.type})
        }
      }
    }
  },
  methods: {
    ...mapMutations('fieldRecordSystem/mapManagement', [
      'selectMultiple',
      'deselectMultiple'
    ])
  }
}

<template lang="html">
  <div class="map-item-menu" @click.stop>
    <div v-if="type !== 'nutrientMap' && type !== 'fieldBorder'" :title="$i18n.translate('Common.SR_Common.ChangeMapName')">
      <FontAwesomeIcon
        :class="rowSelected ? 'icon' : 'row-not-selected'"
        :icon="icons.edit"
        @click="$emit('changeName')"
      />
    </div>

    <div v-if="type === 'zoneMap'" :title="$i18n.translate('Common.SR_Common.ChangeHeterogentity')">
      <FontAwesomeIcon
        :class="rowSelected ? 'icon' : 'row-not-selected'"
        :icon="icons.heterogenity"
        @click="$emit('changeHeterogenity')"
      />
    </div>

    <div
      v-if="
        ($can('ZoneMapDownload') && type === 'zoneMap' && type !== 'nutrientMap' && category !== 'BiomassMap')
          || ($can('feature/application-map-download') && type === 'applicationMap')
          || type === 'nutrientMap'
          || category === 'BiomassMap'
          || type === 'fieldBorder'
          || (type === 'soilMap')"
    >
      <div :title="type !== 'zoneMap' && type !== 'fieldBorder' ? $i18n.translate('Common.SR_Common.Download') : $i18n.translate('frs.mapManagement.titles.downloadZoneMap')">
        <FontAwesomeIcon
          :class="rowSelected ? 'icon download' : 'row-not-selected'"
          :icon="icons.download"
          @click="$emit('download')"
        />
      </div>
    </div>

    <div v-if="type === 'applicationMap' && $can('feature/application-map-download')">
      <div :title="$i18n.translate('Common.SR_Common.ApplicationMapTransfer')">
        <FontAwesomeIcon
          :class="rowSelected ? 'icon export' : 'row-not-selected'"
          :icon="icons.fileExport"
          @click="$emit('export')"
        />
      </div>
    </div>
    <div v-if="type !== 'fieldBorder'">
      <div v-if="permissionDelete">
        <div :title="$i18n.translate('Common.SR_Common.DeleteMap')">
          <FontAwesomeIcon
            :class="rowSelected ? 'icon remove' : 'row-not-selected'"
            :icon="icons.remove"
            @click="$emit('remove')"
          />
        </div>
      </div>
    </div>
    <div v-if="type !== 'fieldBorder'" :title="$i18n.translate('Common.SR_Common.MapDetails')">
      <FontAwesomeIcon
        :class="rowSelected ? 'icon info' : 'row-not-selected'"
        :icon="icons.info"
        @click="$emit('showDetails')"
      />
    </div>
    <!-- <FontAwesomeIcon v-if="type === 'zoneMap'" class="icon" :icon="icons.next" @click="$emit('openPopover', map.id)" /> -->
    <FontAwesomeIcon v-if="category === 'ManagementZoneMap'" :icon="icons.next" class="icon disabled" />
    <div
      v-else-if="category === 'BiomassMap' && $can('Use.Frs.SimpleApplicationMaps')"
      :title="$i18n.translate('frs.mapManagement.hints.toSimpleApplicationMaps')"
    >
      <FontAwesomeIcon
        :icon="icons.next"
        :class="{'disabled': !mapActive, 'icon': rowSelected, 'row-not-selected': !rowSelected}"
        @click="canOpenSimpleApplicationMaps"
      />
    </div>
  </div>
</template>

<script>

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {faDownload, faInfoCircle, faEdit, faHSquare, faEllipsisH, faFileExport} from '@fortawesome/free-solid-svg-icons'
import {faTrashAlt, faArrowAltCircleRight} from '@fortawesome/free-regular-svg-icons'

import PermissionMixin from '@mixins/PermissionMixin'

export default {
  components: {
    FontAwesomeIcon
  },
  mixins: [
    PermissionMixin
  ],
  props: {
    type: String,
    disabled: Boolean,
    category: String,
    rowSelected: Boolean,
    mapActive: Boolean
  },
  computed: {
    icons () {
      return {
        download: faDownload,
        remove: faTrashAlt,
        info: faInfoCircle,
        next: faArrowAltCircleRight,
        edit: faEdit,
        heterogenity: faHSquare,
        openMenu: faEllipsisH,
        fileExport: faFileExport
      }
    },
    permissionDelete () {
      const zoneMapPermission = this.$can('Delete.Frs.ZoneMap') && this.category !== 'BiomassMap'
      const biomassMapPermission = this.category === 'BiomassMap'

      return zoneMapPermission || biomassMapPermission || this.type !== 'zoneMap'
    }
  },
  methods: {
    onChangeMapNameClick () {
      if (!this.disabled) {
        this.$emit('changeName')
      }
    },
    ifNotDisabled (f) {
      if (!this.disabled) {
        f()
      }
    },
    canOpenSimpleApplicationMaps () {
      if (this.mapActive) this.$emit('openSimpleApplicationMaps')
    }
  }
}
</script>

<style lang="scss" scoped>
.map-item-menu {
  display: flex;
  justify-content: flex-end;
}

@mixin iconColor ($color) {
  color: $color;

  &:hover {
    color: darken($color, 10%);
  }
}
.row-not-selected {
  font-size: 1.2em;
  margin-top: 4px;
  margin-left: 8px;
  color: lightgrey;
}
.icon {
  font-size: 1.2em;
  margin-top: 4px;
  margin-left: 8px;

  @include iconColor(#585858);

  &:hover {
    cursor: pointer;
  }

  &.download {
    @include iconColor(#16a024);
  }

  &.export {
    @include iconColor(#fb5c03);
  }

  &.remove {
    @include iconColor(#e00028);
  }

  &.info {
    @include iconColor(#0348a6);
  }

  &.disabled {
    color: #bfbfbf;

    &:hover {
      cursor: not-allowed;
    }
  }
}
</style>

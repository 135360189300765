<template lang="html">
  <LabelAndMessage
    :id="id" :label="label"
    :required="required"
    :feedback-classes="feedbackClasses" :description="combinedDescription"
    :message="message"
  >
    <div ref="picker" class="input-group date">
      <input
        type="text" :name="name"
        :placeholder="placeholder"
        class="form-control" :readonly="readonly"
        :tabindex="tabindex"
      >
      <span class="input-group-addon">
        <span class="glyphicon glyphicon-calendar" />
      </span>
      <InputClearButton :show="value && !readonly" right="46px" @click="clear" />
    </div>
    <template v-if="$slots.label" #label>
      <slot name="label" />
    </template>
    <template v-if="$slots.description" #description>
      <slot name="description" />
    </template>
  </LabelAndMessage>
</template>

<script>
import 'eonasdan-bootstrap-datetimepicker'
import moment from 'moment'
import {getCurrentLocale} from 'src/js/i18n'
import LabelAndMessage from './input-base/LabelAndMessage.vue'
import InputClearButton from '@components/InputClearButton'
import makeResourceMixin from 'src/vue/mixins/make-resource-mixin'

import InputMixin from './input-base/InputMixin'
import FormPartMixin from './FormPartMixin'
import ChangeDetectionMixin from './ChangeDetectionMixin'
import RuleMixin from './RuleMixin'

const experimentalLocalTimeSupport = false

const resources = {
  SR_InfoMessages: 'Messages.Info.SR_InfoMessages'
}
let counter = 0
export default {
  components: {
    LabelAndMessage,
    InputClearButton
  },
  mixins: [
    InputMixin,
    FormPartMixin,
    makeResourceMixin(resources),
    ChangeDetectionMixin,
    RuleMixin
  ],
  // format: see http://momentjs.com/
  // view-mode: 'days', 'months', 'years' and 'decades'
  props: {
    name: String,
    value: {},
    min: {},
    max: {},
    format: {
      type: String,
      default: 'L'
    },
    // viewMode: String,
    iso: Boolean,
    local: Boolean,
    readonly: Boolean,
    placeholder: String,
    label: String,
    description: String,
    required: Boolean,
    tabindex: {
      type: Number,
      default: 0
    },
    dontUseCurrentDate: Boolean
  },
  data () {
    return {
      id: `date-time-input-${counter++}`,
      picker: null
    }
  },
  computed: {
    valueAsMoment () {
      return this.convertToMoment(this.value)
    },
    combinedDescription () {
      return [this.ruleDescription, this.description].filter(x => x)
    },
    message () {
      const builtinMessages = {
        error: this.SR_InfoMessages.RequiredField
      }
      const combinedMessages = Object.assign(builtinMessages, this.ruleMessages)

      return combinedMessages[this.state]
    },
    state () {
      // never validate without user entry
      if (!this.dirty) {
        return undefined
      }

      const states = {}

      if (this.required) {
        if (this.value) {
          states.required = 'success'
        } else {
          states.required = 'error'
        }
      }
      states.rule = this.ruleState

      // the 'or success' part coerces the undefined state that you get when no rules are available into a success state
      return this.combineStates(states) || 'success'
    }
  },
  methods: {
    clear () {
      this.$emit('input', null)
    },
    convertToMoment (value) {
      value = value && this.iso
        ? experimentalLocalTimeSupport
          ? this.format === 'L'
            ? this.local
              ? moment(value).local().startOf('d')
              : moment(value).utc().startOf('d')
            : this.local
              ? moment(value).local()
              : moment(value).utc()
          : this.format === 'L'
            ? moment(moment(value).valueOf()).startOf('d')
            : moment(value)
        : (value || null)

      return value
    },
    onChange () {
      if (this._skipEmit) return
      if (this.picker.date() === null) return this.clear()

      let date

      if (experimentalLocalTimeSupport) {
        date = this.format === 'L'
          ? this.local
            ? this.picker.date().startOf('d').local()
            : this.picker.date().startOf('d').utc()
          : this.local
            ? this.picker.date().local()
            : this.picker.date().utc()
      } else {
        date = this.format === 'L'
          ? this.picker.date().startOf('d')
          : this.picker.date()
      }

      const processedDate = date && this.iso
        ? date.toISOString(this.local && experimentalLocalTimeSupport)
        : date

      if (processedDate === this.value) return

      this.$emit('input', processedDate)
    }
  },
  watch: {
    value (value) {
      this._skipEmit = true
      this.picker.date(this.valueAsMoment)
      this._skipEmit = false
    },
    min (min) {
      this.picker.minDate(this.convertToMoment(min) || false)
    },
    max (max) {
      this.picker.maxDate(this.convertToMoment(max) || false)
    },
    format (format) {
      this.picker.format(format)
    }
  },
  mounted () {
    const options = {
      useCurrent: !this.dontUseCurrentDate, // use current date in case an initial value is not set
      format: this.format,
      locale: getCurrentLocale(),
      minDate: this.convertToMoment(this.min) || false,
      maxDate: this.convertToMoment(this.max) || false,
      date: this.value && this.convertToMoment(this.value)
    }
    // initialize datetimepicker and set dates
    const $picker = $(this.$refs.picker)
    this.picker = $picker.datetimepicker(options).data('DateTimePicker')

    if (this.value && this.format === 'L') {
      this.onChange()
    }

    // since the datetimepicker is initialized we can access the instance as usual
    $picker.on('dp.change', this.onChange)
  },
  beforeDestroy () {
    this.picker.destroy()
  }
}
</script>

<style lang="scss" scoped>
</style>

<template lang="html">
  <div class="filter-container">
    <label class="label-group-filter">
      <IxRes>frs.mapManagement.labels.groupBy</IxRes>:
    </label>
    <div class="toggle-buttons">
      <ToggleButtonGroup
        :toggle-buttons="toggleButtons"
        preselection
        font-weight="normal"
        inline
        @viewSelected="selectedGrouping"
      />
    </div>

    <label class="label-sort-filter">
      <IxRes>frs.mapManagement.labels.sortBy</IxRes>:
    </label>

    <div class="module-filter">
      <label>
        <IxRes>frs.mapManagement.labels.module</IxRes>:
      </label>
      <div class="filter">
        <VueSelectize
          v-model="moduleFilter"
          :options="moduleOptions"
          :fields="moduleFieldConfig"
          allow-empty
        />
      </div>
    </div>
    <div class="cultivation-filter">
      <label>
        <IxRes>frs.mapManagement.labels.culture</IxRes>:
      </label>
      <div class="filter">
        <VueSelectize
          v-model="cropFilter"
          :options="cropUsageOptions"
          :fields="cropUsageFields"
          allow-empty
        />
      </div>
    </div>
    <div class="date-filter">
      <label>
        <IxRes>frs.mapManagement.labels.date</IxRes>:
      </label>
      <div class="filter">
        <VueDateTimePicker v-model="dateTimeFilter" dont-use-current-date />
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import {mapFormFields} from '@helpers/vuex'

import {makeCropUsageFilter, makeDateFilter, makeModuleFilter} from 'src/js/store/modules/field-record-system/map-management/helpers'

import ToggleButtonGroup from '@components/ToggleButtonGroup'
import VueSelectize from '@components/VueSelectize'
import VueDateTimePicker from '@components/forms/VueDateTimePicker'

export default {
  components: {
    ToggleButtonGroup,
    VueSelectize,
    VueDateTimePicker
  },
  props: {
    cropUsageLookup: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapGetters('fieldRecordSystem/mapManagement', [
      'mapsByFieldIdByType'
    ]),
    ...mapFormFields('fieldRecordSystem/mapManagement', [
      'ui.applicationMapSorting',
      'ui.cropUsageFilter',
      'ui.moduleFilter',
      'ui.dateTimeFilter'
    ]),
    cropFilter: {
      get () {
        return this.cropUsageLookup
          ? this.cropUsageFilter
          : null
      },
      set (value) {
        this.cropUsageFilter = value
      }
    },
    maps () {
      return Object.values(this.mapsByFieldIdByType.applicationMap).flat()
    },
    moduleOptions () {
      const origins = this.maps
        .filter(makeCropUsageFilter(this.cropUsageFilter, this.cropUsageLookup))
        .filter(makeDateFilter(this.dateTimeFilter))
        .map(map => map.origin)

      return origins.filter(x => x != null)
    },
    cropUsageOptions () {
      const filteredMaps = this.maps
      .filter(makeModuleFilter(this.moduleFilter))
      .filter(makeDateFilter(this.dateTimeFilter))

      const cropUsageIds = filteredMaps
      .map(map => {
        if (map.cultivationId && this.cropUsageLookup && this.cropUsageLookup[map.cultivationId] !== undefined) {
          return this.cropUsageLookup[map.cultivationId]
        } else {
          return 'noCropUsage'
        }
      })
      let uniqueIds = [...new Set(cropUsageIds)]

      return uniqueIds.filter(x => x != null)
    },
    moduleFieldConfig () {
      return {
        text: value => this.$i18n.translate(`frs.mapManagement.headers.module_${value}`),
        value: value => value
      }
    },
    cropUsageFields () {
      return {
        text: id => this.$i18n.translate(`masterData.crops.usage.${id}_name`),
        value: id => id
      }
    },
    toggleButtons () {
      return this.options.map(opt => (
        {
          title: this.$i18n.translate(`frs.mapManagement.toggle.labels.${opt}`),
          name: this.$i18n.translate(`frs.mapManagement.toggle.labels.${opt}`),
          component: opt
        }
      ))
    },
    options () {
      return ['module', 'application', 'cultivation']
    }
  },
  methods: {
    ...mapMutations('fieldRecordSystem/mapManagement', [
      'setFilterValue'
    ]),
    selectedGrouping (value) {
      this.applicationMapSorting = value
    }
  },
  watch: {
    cropUsageOptions (options) {
      this.setFilterValue({options, type: 'cropUsage'})
    },
    moduleOptions (options) {
      this.setFilterValue({options, type: 'module'})
    }
  },
  beforeDestroy () {
    this.cropUsageFilter = null
  }
}
</script>

<style lang="scss" scoped>
.filter-container {
  display: grid;
  grid-template-columns: 20% 25% 30% 25%;
  grid-gap: 5px 0px;
}
.label-group-filter {
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  display: flex;
  align-items: center;
}
.toggle-buttons {
  grid-column: 2 / span 3;
  grid-row: 1 / span 1;
}
.label-sort-filter {
  grid-column: 1 / span 1;
  grid-row: 2 / span 1;
  display: flex;
  align-items: center;
}
.module-filter {
  grid-column: 2 / span 1;
  grid-row: 2 / span 1;
  display: flex;
  flex-direction: column;
}
.cultivation-filter {
  grid-column: 3 / span 1;
  grid-row: 2 / span 1;
  display: flex;
    flex-direction: column;
}
.date-filter {
  grid-column: 4 / span 1;
  grid-row: 2 / span 1;
  display: flex;
  flex-direction: column;
}
.filter {
  width: 90%;
}
</style>
